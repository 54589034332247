const enApplication = [
    {
        applicationid: "healthy-living",
        applicationTitle: "Healthy Living",
        id: "glutathione-treatment",
        image: "/img/applications/saglikli-yasam/GLUTATYON.jpg",
        title: "Glutathione Therapy",
        content: {
            blogPageTitle: "Glutathione Therapy",
            blogPageDesc: "Why is Glutathione Important? Glutathione is a powerful antioxidant that acts as a scavenger of oxidative substances and a detoxifying agent. Glutathione is one of the most important antioxidants produced in the body, but its levels can decrease due to poor diet, pollution, stress, smoking, alcohol, inadequate sleep, and infections.",
            metaDescription: "Glutathione therapy is one of the most effective methods used today. It helps to increase the level of glutathione and make it actively used in the body."
        },
        accordeon: [
            {
                title: "Why is Glutathione Important?",
                description: '<p style="text-align: justify;">Glutathione is a powerful antioxidant that acts as a scavenger of oxidative substances and a detoxifying agent. It is one of the most important antioxidants produced in the body. However, its levels can decrease due to inadequate intake of glutathione precursors in the diet, poor nutrition, pollution, stress, smoking, alcohol, inadequate sleep, and infections. The most important factor is the decrease in production during the aging process. Glutathione levels begin to decrease after the age of 20 and can be noticeably insufficient after the age of 40. Exposure to blue light at night (use of computers, tablets, and cell phones) decreases melatonin levels. Melatonin is important for glutathione synthesis. Stressful lifestyles, excessive anxiety, and depression decrease glutathione levels. Glutathione circulates in the body and collects free radicals. When it can no longer absorb more free radicals, it goes to the liver and is renewed there. In cells, 95% of total glutathione is unoxidized, while the remaining 5% is oxidized glutathione. There are three main factors that reduce glutathione content in tissues and cells.</p><br />1- Increased use of glutathione,<br />2- Limited glutathione synthesis,<br />3- Decreased conversion of oxidized glutathione to unoxidized glutathione within cells.<br />When the active glutathione level in the body falls below 90%, free radicals start to damage the body. As toxic substances continue to accumulate, glutathione levels in the body decrease further. When glutathione levels drop below 70%, serious problems start to appear in the immune system. For these reasons, it is necessary to take glutathione supplements from external sources.<br />Glutathione deficiency can result in chronic fatigue, unhealthy and dull skin appearance, muscle and joint weakness, and most importantly, a slowdown in the immune system.</p>'
            },
            {
                title: "How Should Glutathione Therapy Be Applied?",
                description: '<p style="text-align: justify;">The most effective way to increase glutathione levels and ensure its active use in the body is to take glutathione intravenously. Taking glutathione orally does not achieve the desired effect as it dissolves directly in the stomach and does not reach the cells. Therefore, if there is a need for glutathione, it should be administered intravenously.</p>'
            },
            {
                title: "Which Foods Contain Glutathione?",
                description: '<p style="text-align: justify;">As part of our immune system, glutathione, an antioxidant substance that cleans up toxic waste in the body, is composed of three amino acids (glutamine, cysteine, and glycine). Consuming foods rich in sulfur such as garlic, onions, cabbage, cauliflower, tomatoes, and broccoli, and whey (a by-product of cheese production) is important for glutathione synthesis in the body. Unfortunately, due to pesticides and genetically modified foods, we cannot adequately obtain the necessary amount of glutathione from our diet.</p>'
            },
            {
                title: "What Are the Costs of Glutathione Therapy?",
                description: '<p style="text-align: justify;">The cost of treatment varies depending on the number of sessions or doses. Therefore, you need to consult a specialist, create a plan, and start treatment according to this plan to determine the cost.</p>'
            },
            {
                title: "Are There Any Side Effects of Glutathione Therapy?",
                description: '<p style="text-align: justify;">Glutathione therapy, which is actively used worldwide to minimize the damage caused by free radicals in the body or to reduce the effects of potential diseases, has no known side effects. Since it is a compound naturally synthesized in the body, and the treatment aims to increase its concentration, no side effects are observed.</p>'
            },
            {
                title: "Who Should Not Use Glutathione Therapy?",
                description: '<p style="text-align: justify;">It is not recommended for pregnant women, breastfeeding mothers, and children as there are not enough studies on these groups. It is also not recommended during active chemotherapy and radiotherapy treatment periods. However, after the patient’s treatment is completed, glutathione supplementation can be applied to help the body recover faster.</p>'
            }
        ]
    },
    {
        applicationid: "healthy-living",
        applicationTitle: "Healthy Living",
        id: "ozone-treatment",
        image: "/img/applications/saglikli-yasam/ozon.jpg",
        title: "Ozone Therapy",
        content: {
            blogPageTitle: "Ozone Therapy",
            blogPageDesc: "Ozone (O3) is a molecule consisting of three atoms and is a chemical cousin of O2. Ozone was discovered by the Austrian chemist Christian Schönbein in 1840. It is a gas at room temperature and has a colorless, distinctive odor.",
            metaDescription: "Ozone therapy is performed by administering a certain proportion of ozone/oxygen mixture into body cavities or the circulatory system. It is used on the skin to increase circulation and help regain impaired biological functions.",
            blogDetailPageInfo: [
                {
                    description: '<p style="text-align: justify;">Ozone (O3) is a molecule consisting of three atoms and is a chemical cousin of O2. Ozone was discovered by the Austrian chemist Christian Schönbein in 1840. It is a gas at room temperature and has a colorless, distinctive odor.</p>'
                }
            ]
        },
        accordeon: [
            {
                title: 'General Effects of Ozone',
                description: '<ul style="text-align: justify;"><li>Stimulation of Cell Regeneration</li><li>Activation of Antioxidant Systems</li><li>Increase in Microcirculation</li><li>Antiaggregant Effect</li><li>Increased Flexibility and Elasticity of Erythrocyte Membranes</li><li>Reduced Blood Viscosity and Circulation Restoration</li><li>Increased Oxygen Transport and Release to Tissues</li><li>Antibacterial</li><li>Antiviral</li><li>Antifungal Effect</li><li>Anti-inflammatory Effect</li><li>Antiallergic Effect</li><li>Analgesic Effect</li><li>Rapid Wound Healing Effect</li><li>Sterilizing and Disinfectant Effect</li><li>Detoxification Effect</li><li>Enzymatic, Hormonal, and Metabolic Regulation</li></ul>'
            },
            {
                title: 'Ozone in Aesthetic Dermatology',
                description: '<p style="text-align: justify;">Skin rejuvenation and wrinkle reduction <br /> Cellulite treatment <br /> Treatment of infected areas <br /> Pre- and post-hair transplantation <br /> Regional and general weight loss (Ozone Sauna)</p>'
            },
            {
                title: 'Ozone in General Protective Health',
                description: '<p style="text-align: justify;">Detoxification and anti-aging effects <br /> Vitality and well-being <br /> Chronic fatigue syndrome <br /> Healthier tissue nourishment <br /> Regulation of circulation <br /> Strengthening of antioxidant systems</p>'
            },
            {
                title: 'Who Should Not Use Ozone Therapy?',
                description: '<ul style="text-align: justify;"><li>Acute Hemorrhagic Diseases (MI, CVA in the first 21 days)</li><li>Patients with G6PD enzyme deficiency</li><li>Pregnancy (especially the first trimester due to mutagenicity risk)</li><li>Patients taking anticoagulants and ACE inhibitors</li><li>Hyperthyroidism (due to increased metabolism)</li><li>Thrombocytopenia and severe bleeding conditions</li><li>Patients who have had organ transplants.</li></ul>'
            }
        ]
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "cost-of-cosmetic-procedures",
        image: "/img/applications/dolgu-uygulamalari/kozmetik-islemlerin-maliyeti.jpg",
        title: "Cost of Cosmetic Procedures",
        content: {
            blogPageTitle: "Cost of Cosmetic Procedures",
            blogPageDesc: "Over the years, cultural and social values have rapidly changed and continue to do so. Visual and social media have come to the forefront in every aspect of our lives over the last decade. This situation has increased the desire for a young and dynamic appearance in every age group of women and men and consequently the demand for cosmetic procedures.",
            metaDescription: "In recent years, non-surgical cosmetic procedures have been frequently performed. What are the costs of these applications? Click for detailed information!",
            blogDetailPageInfo: [
                {
                    description: '<span style="font-weight: 400;">Over the years, cultural and social values have rapidly changed and continue to do so. Visual and social media have come to the forefront in every aspect of our lives over the last decade. This situation has increased the desire for a young and dynamic appearance in every age group of women and men and consequently the demand for cosmetic procedures. Today, cosmetic applications are very dynamic. Despite its dynamic nature, most patients expect the procedures to be quick, effective, reliable, modern, and to have minimal side effects that allow them to return to work and daily life quickly. Methods defined as "Non-Surgical Aesthetic Procedures" have been developed for this purpose and are the most preferred applications.</span> <br /> <span style="font-weight: 400;">The cost of cosmetic procedures is very important for everyone, and since these procedures require continuity, you need to allocate a certain budget.</span> <span style="font-weight: 400;">Remember: Injection applications are a medical procedure. Before injecting anything into your face, please ask your doctor about the brand of the product used, the filler product, and the ingredients of any product to be injected. Of course, the fact that a procedure is expensive does not necessarily mean it will give the best result, but if a procedure is cheap, it usually means it is cheap for a reason.</span>',
                }
            ]
        }
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "filling-application",
        image: "/img/applications/dolgu-uygulamalari/dolgu-uygulamasi.jpg",
        title: "Filler Application",
        content: {
            blogPageTitle: "Filler Application",
            blogPageDesc: "With aging, the deep fat tissues that support our face shrink, while the superficial fat tissues grow and shift. Around the eyes, forehead, cheekbones, temples, chin, and eyebrows, the deep fat tissues shrink, while the superficial fat tissues become more prominent around the jawline, nasolabial folds, under the eyes, and along the jawline.",
            metaDescription: "Today, non-surgical procedures related to facial aesthetics are much more preferred. Filler applications are the second most popular cosmetic procedure after botulinum toxin applications.",
            blogDetailPageInfo: [
                {
                    description: '<span style="font-weight: 400;">With aging, the deep fat tissues that support our face shrink, while the superficial fat tissues grow and shift. Around the eyes, forehead, cheekbones, temples, chin, and eyebrows, the deep fat tissues shrink, while the superficial fat tissues become more prominent around the jawline, nasolabial folds, under the eyes, and along the jawline. Gravity, aging-related changes in the skeletal structure of the face (shrinkage), weakening of ligaments that hold tissues together, sun-UV exposure, smoking, inadequate sleep, high glycemic index and trans-fat diet, air pollution, and menopause contribute to the changes in facial harmony and aesthetics.</span><span style="font-weight: 400;">Today, non-surgical procedures related to facial aesthetics are much more preferred. Filler applications are the second most popular cosmetic procedure.</span><br /><br /><b>Aging Results In:</b><br /><ul><li><span style="font-weight: 400;">Fine lines and deep wrinkles</span></li><li><span style="font-weight: 400;">Downward and inward shifting of aesthetic anatomical areas due to gravity</span></li><li><span style="font-weight: 400;">Reduction of fat tissue in certain anatomical areas (sunken temples) and increase in others (under the chin and jawline), resulting in a wider lower face</span></li><li><span style="font-weight: 400;">Reduction of bone and cartilage tissues (drooping of the nose tip, flattening of the cheekbones, and flattening of the cheeks)</span></li><li><span style="font-weight: 400;">Drying, dullness, and increased spots on the skin</span></li><li><span style="font-weight: 400;">Filler applications are performed to prevent and treat these cosmetic problems. Correct results are achieved with proper application in the right areas.</span></li></ul>',
                }
            ]
        }
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "classification-of-filler-materials",
        image: "/img/applications/dolgu-uygulamalari/dolgu-maddelerinin-siniflandirilmasi.jpg",
        title: "Classification of Filler Materials",
        content: {
            blogPageTitle: "Classification of Filler Materials",
            blogPageDesc: "Filler Materials: Non-Permanent Non-permanent fillers have a durability of less than 1 year. They dissolve within this period and need to be repeated for longer-lasting results. Non-permanent fillers include collagen fillers and hyaluronic acid-containing fillers.",
            metaDescription: "There are many classifications of filler materials. Permanent, semi-permanent, non-permanent... Click for detailed information!",
            blogDetailPageInfo: [
                {
                    detailTitle: 'Filler Materials:',
                },
                {
                    detailTitle: 'Non-Permanent',
                    description: 'Non-permanent fillers have a durability of less than 1 year. They dissolve within this period and need to be repeated for longer-lasting results. Non-permanent fillers include collagen fillers and hyaluronic acid-containing fillers.'
                },
                {
                    detailTitle: 'Semi-Permanent Fillers',
                    description: 'Semi-permanent fillers (Smart Fillers) have a durability of 1-2 years. This group includes fillers containing poly-L-lactic acid, calcium hydroxyapatite, or polycaprolactone.'
                },
                {
                    detailTitle: 'Permanent Fillers',
                    description: 'Permanent fillers have a durability of more than 2 years. These include PMMA (polymethyl methacrylate), PAAG (polyacrylamide gel), and silicone (polydimethylsiloxane oil). They are not commonly preferred in clinics.'
                },
                {
                    description: 'When choosing and applying filler materials, the appropriate filler product is selected based on the three-dimensional structure of the patient’s face, the areas to be treated, and the overall aesthetic harmony of the face. Therefore, the technical knowledge and training of the doctor performing the application, as well as their aesthetic understanding and taste, are extremely important. <br />To prevent subcutaneous bleeding and bruising during the application, it is recommended to stop taking vitamin E, green tea, alcohol, fish oil, garlic, pomegranate, gingko biloba, aspirin, and pain relievers 5 days prior to the procedure to reduce the risk of bruising after the procedure.'
                }
            ]
        }
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "smart-filler",
        image: "/img/applications/dolgu-uygulamalari/akilli-dolgu-nedir.jpg",
        title: "What is Smart Filler?",
        content: {
            blogPageTitle: "What is Smart Filler?",
            blogPageDesc: "Hyaluronic acid fillers are the most frequently used in filler applications. If the filler provides both lifting and volumizing effects and stimulates the synthesis of collagen-elastin fibers, these fillers are called 'smart fillers.'",
            metaDescription: 'If the filler provides both lifting and volumizing effects and stimulates the synthesis of collagen-elastin fibers, these fillers are called "smart fillers." Click for detailed information!',
            blogDetailPageInfo: [
                {
                    description: 'Hyaluronic acid fillers are the most frequently used in filler applications. If the filler provides both lifting and volumizing effects and stimulates the synthesis of collagen-elastin fibers, these fillers are called “smart fillers.” Smart fillers have a longer durability than hyaluronic acid fillers, with a durability of 1-2 years. This group includes fillers containing poly-L-lactic acid, calcium hydroxyapatite, or polycaprolactone.'
                },
                {
                    description: 'Smart fillers are applied to the forehead, temples, cheekbones, cheeks, chin, jawline, skin stretch marks, and acne scars. Due to their high lifting effect, they are an excellent choice for areas such as the temples, cheekbones, and mid-face.'
                },
                {
                    description: 'In recent years, different dilutions of these highly viscoelastic products are being prepared with saline solution and lidocaine (such as 1:1, 1:2…1:6). Dilution products can be applied more superficially in the target tissue. Products prepared with high dilutions provide less volumizing effect but allow for a wider spread in the application area, stimulating the regeneration of subcutaneous tissue (increasing collagen, elastin production) and stimulating fibroblasts. Fibroblasts have a critical role in creating, regulating, and repairing the tissue architecture of the skin. The term fibroblast means thread-making cell. The proteins that shape connective tissue threads are synthesized by these cells. Fibroblasts are responsible for producing collagen fibers, elastin fibers, and hyaluronic acid. Collagen protein is the most important acellular (non-cellular) protein of the dermis. Collagen constitutes 70% of the dry weight of the dermis. Type I and Type III collagens represent 80% and 15% of dermal collagen, respectively, while elastin fibers account for only 2%. Gly cosaminoglycans (hyaluronic acid) are the third important component of the dermis. All these components are synthesized by fibroblast cells. Increased collagen and elastin production leads to an increase in dermal thickness, elasticity, and tension, resulting in skin rejuvenation. Diluted applications can be repeated 1-3 times at intervals of 6 weeks (the number of sessions is determined based on the patient’s ability to generate new collagen-elastin-glycosaminoglycans). Clinical and aesthetic results are observed 4-6 weeks later. This application is also known as mineral vaccination.'
                },
                {
                    description: 'In summary, smart (semi-permanent) fillers; without dilution, semi-permanent fillers are used for volumizing purposes in the tissue, while diluted semi-permanent fillers (depending on the dilution ratio) are particularly known as “dermal stimulants” due to their ability to promote the production of new and organized collagen in the dermis. They are thought to stimulate new collagen formation by stimulating fibroblasts and gradually rebuilding facial volume. Increased collagen and elastin synthesis results in improved elasticity, reduced surface wrinkles, and sagging.'
                }
            ]
        }
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "lip-filler",
        image: "/img/applications/dolgu-uygulamalari/dudak-dolgusu.jpg",
        title: "Lip Filler",
        content: {
            blogPageTitle: "Lip Filler",
            blogPageDesc: "Today, both women and men value fuller-looking lips. Lip volumes vary by ethnicity. Those with African ethnic lips have larger lip volumes, and the upper and lower lip volumes are often nearly identical. In our society, however, lip volumes vary individually, but the average lower lip volume is expected to be 1.5-1.7 times thicker than the upper lip.",
            metaDescription: "If you have volume loss in your lips or want to have more cosmetically fuller lips, you can get lip fillers. Click for detailed information!",
            blogDetailPageInfo: [
                {
                    description: 'Today, both women and men value fuller-looking lips. Lip volumes vary by ethnicity. Those with African ethnic lips have larger lip volumes, and the upper and lower lip volumes are often nearly identical. In our society, however, lip volumes vary individually, but the average lower lip volume is expected to be 1.5-1.7 times thicker than the upper lip.'
                },
                {
                    title: 'Changes in Lips with Age',
                    description: 'During the aging process, lip volume gradually decreases: the upper and lower lips become thinner, curl inward, and droop at the corners of the mouth. The cupid’s bow (the area defined by the two high-arched points of the upper lip) fades and flattens. Over time, the beautiful, prominent, curved structure of the upper lip is lost, resulting in a thin, inward-turned upper lip. The edges of the lips turn downward with the overall loss of lip volume.'
                },
                {
                    description: 'If you have volume loss in your lips or want more cosmetically fuller lips, you can get lip fillers.'
                },
                {
                    title: 'Pre-Evaluation for Lip Filler',
                    description: 'Before the application, the patient’s lips are evaluated functionally and aesthetically (the contour, volume, projection, and symmetry of the lips are assessed). Different techniques are applied in lip filler applications depending on the individual’s desired lip shape and lip anatomy. The primary goal in all techniques is to inject the filler material in a way that provides the desired volume, contour, projection, and aesthetic harmony to the lips. The fundamental differences between them lie in the injection angle according to the lip anatomy, application areas, and the amount of filler applied. A different technique is used in the so-called Russian lip filler application.'
                },
                {
                    description: 'Injections should be applied correctly with the right filler and techniques to minimize vascular damage in the lip tissue, prevent serious complications from the filler being injected into a vessel, and avoid undesirable aesthetic results.'
                },
                {
                    description: 'Before the application, please inform your doctor if you frequently get cold sores. It is expected that lip filler can trigger cold sores. Cream treatment can be started after the application to prevent the potential activation of cold sores.'
                },
                {
                    title: 'How is Lip Filler Applied?',
                    description: 'Local anesthesia is applied to the lip area before the procedure, and after a waiting period of 20 minutes, the appropriate asepsis is ensured in the lip area, and the procedure is started. It takes about 15-20 minutes. The individual can return to normal life after the procedure. A follow-up application is done two weeks after the procedure. The durability period varies from person to person, ranging from 9-12 months. However, your filler does not completely dissolve; about 15-20% of the filler material remains after each filler session.'
                },
                {
                    title: 'Post-Lip Filler Care',
                    description: 'After the application; the first 24 hours of bath, pool, and sea activities are restricted. For the first 1-2 days, there may be swelling, redness, and mild pain in the lips. There may be slight bruising. After the application, you can apply a cold compress every two hours for 10 minutes to reduce swelling and redness (especially on the day of the procedure). After all filler applications, you should drink 2.5-3 liters of water daily because hyaluronic acid fillers retain water and settle much better as they retain water. After filler applications, we should not neglect to drink water. Duration: at least 4-5 days.'
                },
                {
                    title: 'Russian Lip Filler',
                    description: 'If you are active on social media and interested in cosmetic applications, you must have seen this new aesthetic trend: RUSSIAN LIP FILLER.'
                },
                {
                    title: 'Russian Lips Appearance',
                    description: 'The new trend lip filler has introduced a new shape to the lips, giving them a flatter, wider appearance. This trend application began because of the desire for larger lips in volume and fullness without the concern of “duck lips” or “trout pout” appearances that many people want to avoid when getting lip fillers. Russian lips have a flatter, higher appearance at the center, derived from the “Russian Doll.”'
                },
                {
                    description: 'The Russian Lip Technique involves injecting small drops of dermal filler around the center to achieve a heart-shaped appearance. The filler is injected vertically at multiple injection points and, unlike traditional lip fillers, is applied from the base to the top of the lip. Russian lips are taller rather than volumized. The most significant difference between this technique and others is that the lips remain relatively flat from the side. To get a Russian lip filler, you should either have no previous lip filler or, if you have had a previous lip filler, it should be dissolved first as the existing filler may prevent the lips from being lifted.'
                }
            ]
        }
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "under-eye-light-filler",
        image: "/img/applications/dolgu-uygulamalari/goz-alti-isik-dolgusu.jpg",
        title: "Under Eye Light Filler",
        content: {
            blogPageTitle: "Under Eye Light Filler",
            blogPageDesc: "The first changes related to aging start in the under-eye area. Due to structural characteristics, folds, lines, localized tissue collapses, puffiness, and dark circles may appear under the eyes at an early age or due to aging.",
            metaDescription: "Due to structural characteristics, folds, lines, localized tissue collapses, puffiness, and dark circles may appear under the eyes at an early age or due to aging. You can find solutions to these problems with mesotherapy and under-eye light filler. Click for detailed information!",
            blogDetailPageInfo: [
                {
                    description: 'The first changes related to aging start in the under-eye area. Due to structural characteristics, folds, lines, localized tissue collapses, puffiness, and dark circles may appear under the eyes at an early age or due to aging.'
                },
                {
                    description: '1. The groove that forms in the inner part of the lower eyelid from the side of the nose to the outer edge of the eye is called the tear trough.'
                },
                {
                    description: '2. Puffiness develops in the lower eyelid, making the groove under the puffiness more pronounced.'
                },
                {
                    description: '3. The subcutaneous fat pads in the transition area between the lower eyelid and the cheek become more superficial with aging and appear as puffiness, further worsening the under-eye appearance.'
                },
                {
                    description: 'During the aging process, the elasticity of the eyelid skin also decreases, making fine wrinkles more prominent. The elasticity of the eyelid skin is indicated by how quickly it returns to its original state when pulled down. A decrease in the return time shows a loss of elasticity.'
                },
                {
                    description: 'The eyes form the most important aesthetic area of our face. Studies show that the eyes are the first anatomical area noticed in social interactions. For all the aging-related changes around the eyes, it is important to know the structural anatomy of the eye area, accurately evaluate the existing problems, and plan appropriate treatments.'
                },
                {
                    title: 'Under Eye Light Filler',
                },
                {
                    title: 'Application',
                    description: 'The groove formation under the eyes with age is due to the reduction of subcutaneous support tissue. This groove area cosmetically disturbs people because it makes them look tired, unhappy, and older. The under-eye light filler is planned for these under-eye problems.'
                },
                {
                    description: 'Hyaluronic acid is the best and safest option for under-eye light filler applications.'
                },
                {
                    description: 'Since there is a high likelihood of developing edema in under-eye light filler applications, a 60-70% correction is always done. The patient is called for a follow-up 2-3 weeks later for reapplication if necessary. The edema that may develop can subside within a few days. Since there is a possibility of developing edema, it is much better to schedule the application at a time suitable for you, especially if you have an important meeting, social activity, or a work environment where you may feel uncomfortable due to edema. Since the severity of the edema that may develop varies from person to person, your doctor cannot give a definite rate to determine the severity of the edema. Some patients do not develop any edema after the application.'
                },
                {
                    description: 'There is a possibility of bruising after filler applications, and this possibility is most common in under-eye filler applications.'
                },
                {
                    title: 'Under Eye Darkening',
                    description: 'Darkening around the eyes is a common problem worldwide. It can occur in both young and old individuals, but the presence of under-eye dark circles at any age causes significant aesthetic concern as it makes the person look sad, tired, stressed, and old. Under-eye dark circles are structural or age-related.'
                },
                {
                    title: 'Under Eye Dark Circles;',
                    description: '1. Due to increased pigmentation under the eyes,'
                },
                {
                    description: '2. Due to vascular structure disorders, or'
                },
                {
                    description: '3. Due to hollows and grooves under the eyes.'
                },
                {
                    description: 'The treatment of under-eye dark circles requires distinguishing between these three conditions.'
                },
                {
                    description: 'Under-eye darkening can also be caused by atopic dermatitis, allergies, sleep disorders, or nutritional irregularities.'
                },
                {
                    description: 'The cause must be determined before planning the treatment because each has a different treatment approach.'
                },
                {
                    description: 'For this, we stretch the lower eyelid upward;'
                },
                {
                    description: '* If the darkening continues, this is true pigmentation due to an increase in pigment cells in the skin. In this case, treatments to reduce the pigment are applied.'
                },
                {
                    description: '* If the darkening disappears when stretched upward, it is due to reduced subcutaneous support tissue and under-eye hollows. In this case, we prefer under-eye fillers.'
                },
                {
                    description: '* If the darkening turns purple when stretched upward, it is due to vascular problems, and treatments for this condition are planned.'
                },
                {
                    description: 'Your treatment protocol for under-eye darkening is determined after your examination.'
                }
            ]
        }
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "earlobe-filler",
        image: "/img/applications/dolgu-uygulamalari/kulak-meme-dolgusu.jpg",
        title: "Earlobe Filler",
        content: {
            blogPageTitle: "Earlobe Filler",
            blogPageDesc: "One of the less common but very beneficial uses of filler applications is to rejuvenate the appearance of the thinned earlobe. With aging, heavy earring use, or structurally existing conditions, sagging, thinning, or deformities in the earlobe can be corrected with filler applications.",
            metaDescription: "With filler application, we can recreate a more pleasant and youthful-looking earlobe shape and restore it to its original state. Click for detailed information!",
            blogDetailPageInfo: [
                {
                    description: 'One of the less common but very beneficial uses of filler applications is to rejuvenate the appearance of the thinned earlobe. With aging, heavy earring use, or structurally existing conditions, sagging, thinning, or deformities in the earlobe can be corrected with filler applications. Using fillers, we can recreate a more pleasant and youthful-looking earlobe shape and restore it to its original state.'
                },
                {
                    title: 'Why is Earlobe Filler Treatment Done?',
                    description: 'Because it is a quick and easy solution that instantly plumps thin, sagging, wrinkled, or shapeless earlobes, resulting in a more pleasant, younger-looking appearance and shape.'
                },
                {
                    title: 'How is the Procedure?',
                    description: 'Dermal filler is a quick and easy solution for damaged, torn, or split earlobes. Hyaluronic acid filler injected into the damaged earlobe adds volume and restores a more natural shape. It helps to plump up stretched, sagging, and thinned earlobes.'
                },
                {
                    title: 'How Long Do the Results Last?',
                    description: 'Earlobe filler injections are likely to last longer than lip or facial filler injections because there is less wear and tear in the lobule area. Earlobe filler lasts an average of 12 months for many people but can provide permanent improvement for some.'
                }
            ]
        }
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "chin-jawline-filler",
        image: "/img/applications/dolgu-uygulamalari/cene-dolgusu.jpg",
        title: "Chin - Jawline Filler",
        content: {
            blogPageTitle: "Chin - Jawline Filler",
            blogPageDesc: "Chin Filler The facial appearance of men and women differs. The chin structure is important among these differences. Women have a smaller, more oval-pointed chin structure, while men have a wider and more square appearance.",
            metaDescription: "With filler application, you can have a more attractive and youthful-looking jawline structure. Click for detailed information!",
            blogDetailPageInfo: [
                {
                    title: 'Chin Filler',
                    description: 'The facial appearance of men and women differs. The chin structure is important among these differences. Women have a smaller, more oval-pointed chin structure, while men have a wider and more square appearance. Especially in men, the chin structure is important for a masculine face structure.'
                },
                {
                    title: 'Individuals Applying for Chin Filler Generally Fall into Two Categories:',
                    description: '1. The first group consists mainly of young individuals with a genetically small chin or those who want to improve their appearance by making their face shape more feminine or masculine.'
                },
                {
                    description: '2. The second group consists of individuals whose chin structure has changed with age due to the reduction of both bone tissue and collagen-elastin tissue in the skin, resulting in a smaller chin structure.'
                },
                {
                    title: 'How is the Application Done:',
                    description: 'Chin filler is important for the characteristic appearance of the face and varies between men and women. For example, making the chin too wide can masculinize a woman’s face. Therefore, the doctor performing the application must be experienced and knowledgeable in this area.'
                },
                {
                    description: 'The application is done with hyaluronic acid or calcium hydroxyapatite fillers. The amount of filler to be used is determined based on the individual’s chin structure.'
                },
                {
                    description: 'After the application, it is normal for the chin area to be sensitive, and this may last a few days. After every filler application, drinking 2-3 liters of water daily for at least three days is necessary.'
                },
                {
                    title: 'Jawline Filler',
                    description: 'Chin and jawline filler applications are often performed together because it is necessary to harmonize the two areas to achieve sharp contours in the lower face region.'
                },
                {
                    description: 'The jawline differs between men and women. A sharp jawline is considered attractive for both sexes, but a thinner sharp line is required for women. The jaw angle is more pronounced in men and appears more angular. The jaw angle should be 90-100 degrees in men and 120-130 degrees in women.'
                },
                {
                    title: 'Individuals Applying for Jawline Filler Generally Fall into Two Categories:',
                    description: '1. Young individuals who want to have jawline filler for cosmetic purposes to achieve a more feminine or masculine appearance or to enhance the attractiveness of their gender.'
                },
                {
                    description: '2. Older individuals who experience a loss of sharp jawline appearance, irregular contours, and blunted mandibular angles due to bone resorption, soft tissue descent, and skin laxity with age. Jawline filler is applied to reduce this appearance in the lower face region.'
                },
                {
                    title: 'How is Jawline Filler Application Done?',
                    description: 'Jawline filler applications vary between men and women. Therefore, the experience and expertise of the person performing the application are important.'
                },
                {
                    description: 'In jawline filler, filler is applied to the jawline between the chin and the jaw corner, the jaw corner, and to form the jaw angle. Therefore, the amount of filler used can vary between 4 and 8 syringes, depending on the individual’s anatomical structure and expectations.'
                },
                {
                    description: 'Hyaluronic acid or calcium hydroxyapatite fillers are used as the filler product.'
                },
                {
                    title: 'Duration of Jawline Filler Application:',
                    description: 'The duration of hyaluronic acid fillers is about one year, while calcium hydroxyapatite fillers last about 1.5-2 years.'
                },
                {
                    description: 'After the fillers dissolve, they do not completely disappear. Since they stimulate the skin, increasing collagen tissue, and because not all the fillers dissolve, you will look 15-20% better than before each filler duration is completed. Repeated applications extend the duration of permanence.'
                },
                {
                    description: 'Cheekbones represent 70% of facial beauty, and individuals increasingly recognize the importance of cheekbones, making cheek filler-midface filler the second most common dermal filler application after lip filler. Cheek filler technique is one of the most effective methods for volumizing the cheeks and contouring them. Cheek-midface filler will restore a pleasant architecture and youth to the facial features. If you want to have prominent and full cheekbones, you can consider cheek filler.'
                }
            ]
        }
    },
    {
        applicationid: "filler-applications",
        applicationTitle: "Filler Applications",
        id: "midface-and-cheek-filler",
        image: "/img/applications/dolgu-uygulamalari/orta-yuz-ve-yanak-dolgusu.jpg",
        title: "Midface and Cheek Filler",
        content: {
            blogPageTitle: "Midface and Cheek Filler",
            blogPageDesc: "Cheekbones represent 70% of facial beauty, and individuals increasingly recognize the importance of cheekbones, making cheek filler-midface filler the second most common dermal filler application after lip filler.",
            metaDescription: "Midface filler treatment aims to increase support in the malar region, providing a youthful appearance. Click for detailed information",
            blogDetailPageInfo: [
                {
                    title: 'Who is Suitable for Cheek Filler:',
                    description: 'Volume Loss in the Cheeks: With aging, the cheeks may start to look sunken, and the midface areas lose their healthy, youthful contours. It is possible to restore volume to the cheeks with filler.'
                },
                {
                    description: 'Pronounced Nasolabial Folds: Also known as smile lines, nasolabial folds are parentheses-like lines that extend from the nose to the corners of the mouth. With aging, due to the loss of volume, firmness, and elasticity, nasolabial folds become more pronounced. Another reason for the increased prominence of nasolabial folds is the downward and forward sagging of the face. Filler applied to the cheeks will lift the face upwards and outwards, reducing the prominence of nasolabial folds. Nasolabial folds are among the biggest indicators of age.'
                },
                {
                    description: 'Cosmetic Improvement: Many individuals in their 20s and early 30s may want cheek filler to enhance their facial features. The cheekbones are the focal point of the facial bone structure. Volumizing the cheekbones with fillers not only lifts the midface but also gives a pleasant youthfulness to the upper face.'
                },
                {
                    title: 'Midface-Cheek Filler',
                    description: 'A filler injection treatment can take 20-30 minutes.'
                },
                {
                    description: 'Before the procedure, a preliminary consultation will be conducted to evaluate your requests and determine exactly how much filler will be needed. Our clinic offers a free preliminary consultation service before filler injections for each patient. If we agree on the treatment plan, you can have your application on the same day. You can return to your daily activities immediately after the application. There may be a slight possibility of small swellings and bruises, but they will subside within the first few days.'
                },
                {
                    title: 'Post-Cheek Filler Care:',
                    description: 'For the first few days after the injection, avoid sleeping on your cheeks. Try to sleep on your back. Also, avoid strenuous exercise for 48 hours after the injection until the filler fully settles. Avoid touching your face and applying makeup on the day of the procedure.'
                },
                {
                    title: 'Durability of Cheek Filler:',
                    description: 'Cheek fillers last for about a year on average. Fillers do not completely dissolve when they erode; about 15-20% remains each time, extending the duration of permanence with repeated applications.'
                },
                {
                    title: 'Cost of Cheek Filler:',
                    description: 'The cost of cheek filler varies depending on the filler product used and the amount of filler needed. To determine the exact cost, you need to have a preliminary consultation with your doctor.'
                }
            ]
        }
    },
    {
        applicationid: "thread-applications",
        applicationTitle: "Thread Face Lifting Applications",
        id: "general-information-about-thread-face-lifting-methods",
        image: "/img/applications/iple-olan-uygulamalar/iple-yuz-germe-yontemleri-ile-ilgili-genel-bilgiler.jpeg",
        title: "General Information About Thread Face Lifting Methods",
        content: {
            blogPageTitle: "General Information About Thread Face Lifting Methods",
            blogPageDesc: "The human face reflects all the changes of aging like a fabric. Over time, the amount and content of collagen and elastin in the tissues change. The fat compartments in the face shrink, and the muscle tissue and bone structure reduce. All these changes manifest as volume loss in the face.",
            metaDescription: "Click for detailed information about the procedures and results of rejuvenation applications with threads.",
            blogDetailPageInfo: [
                {
                    description: 'The human face reflects all the changes of aging like a fabric. Over time, the amount and content of collagen and elastin in the tissues change. The fat compartments in the face shrink, and the muscle tissue and bone structure reduce. All these changes manifest as volume loss in the face. Combined with gravity, the typical appearance of aging emerges. Moreover, the elastic properties of the skin decrease and disappear over time, making it less able to tolerate volume changes as it used to. All these, combined with deep wrinkles caused by facial expressions, result in an old and tired facial expression. Structures with sufficient volume positioned forward and upward give a young and healthy appearance, while structures positioned backward and downward without volume give an old and unhealthy appearance. This is the basic philosophy often used in facial rejuvenation applications.'
                },
                {
                    title: 'Thread Face Lifting Procedures',
                    description: 'Thread face lifting, or thread lifting, is often defined as "non-surgical face lifting." Thread face lifting is a method used to correct age-related changes in the soft tissues of the face and body. The method is based on lifting and fixing soft tissues using specially designed threads, which can vary, in a minimally invasive cosmetic procedure.'
                },
                {
                    description: 'The threads used are divided into absorbable and non-absorbable (permanent) threads.'
                },
                {
                    title: 'Absorbable Threads',
                    description: 'PDO (Polydioxanone), PLLA (Poly-L-lactic Acid), PLA/CL (Poly-L-lactic Acid + Caprolactone)'
                },
                {
                    title: 'Permanent Threads (Non-Absorbable Threads)',
                    description: 'Made of silicone, polyester, and nylon.'
                },
                {
                    title: 'General Features of Absorbable Threads',
                    description: '<ul><li>Biocompatible</li><li>Safe with widespread use</li><li>Fully absorbable over time</li><li>Provide tensile strength in the tissue</li><li>Collagen-stimulating effect</li></ul>'
                },
                {
                    description: 'The lifting threads placed under the skin are not felt by the individual. Thread lifting definitely creates noticeable changes instantly and produces more natural-looking results than surgical face lifting. Thread lifting is the most suitable method for patients with mild to moderate skin laxity. However, thread lifting is also a good alternative to surgical face lifting for patients with severe sagging who cannot undergo surgery for medical reasons.'
                },
                {
                    title: 'In Summary;',
                    description: '<ul><li>Thread face lifting is a less invasive alternative treatment method compared to surgical face lifting.</li><li>The threads used in thread face lifting also stimulate the production of collagen and elastin fibers, which can help reduce or delay skin aging. The lifting effect is achieved by both the procedure itself and the fibroblasts, collagen, and elastin naturally produced by the skin. This doubles the lifting effect.</li><li>A person does not need general anesthesia for thread face lifting. This shortens the recovery time and eliminates the risk of side effects associated with general anesthesia.</li><li>Thread face lifting is a cheaper alternative to surgical face lifting and has almost no risk of scarring.</li><li>The results of both thread face lifting and surgical face lifting are not permanent.</li></ul>'
                }
            ]
        },
        accordeon: [
            {
                title: 'How is Thread Face Lifting Applied?',
                description: '<p style="text-align:justify">Thread face lifting is a new generation of non-surgical face lifting applications. Very thin needles containing threads are first placed under the skin at the sagging and loose points of the face. These threads provide tension and tightening to the skin by both mechanical effect and stimulating the synthesis of collagen and elastin fibers in the area over time. Thus, it is possible to restore the skin to its former youth and more vibrant state without surgery. The application can be comfortably done in an office setting under local anesthesia. The small hooks on the surface of the threads attach to the tissues, making it easier to lift the desired anatomical area of the face upwards. The ends of the threads are cut off, and the threads are not visible or palpable on the skin surface. They are not noticeable during facial expressions. In addition to face lifting, placing the threads into the fat tissue stimulates the lymphatic system, mobilizing fat deposits. This helps to regain the V-line in the face oval, reducing jowls and double chin appearance.</p>'
            },
            {
                title: 'How Long Does the Thread Face Lifting Procedure Take?',


                description: '<p style="text-align: justify;">The duration of the procedure depends on the number of threads used. However, commonly performed applications take an average of 45 minutes.</p>'
            },
            {
                title: 'Differences Between Non-Surgical and Surgical Face Lifting',
                description: '<p style="text-align:justify">Surgical face lifting or non-surgical thread face lifting does not produce permanent results. Surgical face lifting operations show an effect that lasts up to 5 years. However, general anesthesia is required for the application, and this can be challenging, especially for patients with chronic diseases. There is a recovery period after the surgery. The durability of non-surgical thread face lifting methods is 1-2 years. However, the most important advantages are that it can be applied under local anesthesia and allows immediate return to daily life after the application.</p>'
            },
            {
                title: 'How is Face Lifting with Absorbable Threads Done?',
                description: '<ul><li><span style="font-weight:400">The threads used are 100% compatible with the human body</span></li><li><span style="font-weight:400">The procedure duration is 30-45 minutes</span></li><li><span style="font-weight:400">It is painless with appropriate anesthesia methods</span></li><li><span style="font-weight:400">The application is safe</span></li><li><span style="font-weight:400">The effect is seen immediately</span></li><li><span style="font-weight:400">It also stimulates collagen and elastin fiber production, with this effect becoming evident, especially within 3 months</span></li><li><span style="font-weight:400">It can be done in an office setting</span></li><li><span style="font-weight:400">It leaves no scars</span></li><li><span style="font-weight:400">It can be combined with other applications</span></li><li><span style="font-weight:400">It has no side effects</span></li></ul>'
            },
            {
                title: 'Post-Thread Face Lifting Care',
                description: '<ul><li><span style="font-weight:400">Apply ice immediately after the procedure</span></li><li><span style="font-weight:400">Avoid makeup for at least 24 hours</span></li><li><span style="font-weight:400">Avoid activities that require opening your mouth wide (e.g., eating an apple, laughing out loud) for a week</span></li><li><span style="font-weight:400">Sleep in a high pillow position for about 3-5 nights</span></li><li><span style="font-weight:400">Wash your face gently without rubbing or massaging for 5-7 days</span></li><li><span style="font-weight:400">Avoid excessive face and neck movements for about 2 weeks</span></li><li><span style="font-weight:400">Avoid face and neck massage for a month</span></li><li><span style="font-weight:400">Avoid excessive sun exposure for 2 weeks</span></li><li><span style="font-weight:400">Avoid heavy exercise for 2 weeks</span></li><li><span style="font-weight:400">Do not use the sauna for about 3 weeks</span></li><li><span style="font-weight:400">Avoid dental operations for 3 weeks</span></li></ul>'
            },
            {
                title: 'How Long Does the Thread Face Lifting Procedure Take?',
                description: '<p style="text-align: justify;">The duration of the procedure depends on the number of threads used. However, commonly performed applications take an average of 45 minutes.</p>'
            },
            {
                title: 'Who Should Not Have Thread Face Lifting?',
                description: '<ul><li><span style="font-weight:400">Active infections and herpes in the application area</span></li><li><span style="font-weight:400">Autoimmune diseases</span></li><li><span style="font-weight:400">Keloid tendency</span></li><li><span style="font-weight:400">Neurological and psychological disorders</span></li><li><span style="font-weight:400">Individuals with permanent fillers</span></li><li><span style="font-weight:400">Individuals with bleeding tendencies</span></li><li><span style="font-weight:400">Individuals with severe heart or kidney disease</span></li><li><span style="font-weight:400">Cancer patients</span></li></ul>'
            },
            {
                title: 'Common Complications',
                description: '<span style="font-weight:400"><strong>Edema:</strong> Becomes noticeable in the first 24 hours after treatment and can last up to a week.</span><span style="font-weight:400"><strong>Bruising:</strong> Bruises can develop in the needle entry points within the application areas, especially in eyebrow lifting and around the eyes. They subside within 1-2 weeks.</span><span style="font-weight:400"><strong>Redness:</strong> Redness disappears within 24 hours.</span><span style="font-weight:400"><strong>Tightness and Pain:</strong> Caused by the needles in the application areas.</span>'
            }
        ]
    },
    {
        applicationid: "thread-applications",
        applicationTitle: "Thread Face Lifting Applications",
        id: "spider-web-technique-for-facial-aesthetics",
        image: "/img/applications/iple-olan-uygulamalar/orumcek-agi-teknigi-ile-yuz-estetigi-uygulamasi.jpeg",
        title: "Spider Web Technique for Facial Aesthetics",
        content: {
            blogPageTitle: "Spider Web Technique for Facial Aesthetics",
            blogPageDesc: "Spider web aesthetics is a non-surgical skin tightening, rejuvenation, and shaping procedure. In the 20s, the collagen and elastin fibers in our skin, which ensure the tension and elasticity of the skin, begin to decrease by 1.5% annually.",
            metaDescription: "Spider web aesthetics is a non-surgical skin tightening, rejuvenation, and shaping procedure. Click for detailed information.",
            blogDetailPageInfo: [
                {
                    description: 'Spider web aesthetics is a non-surgical skin tightening, rejuvenation, and shaping procedure. In the 20s, the collagen and elastin fibers in our skin, which ensure the tension and elasticity of the skin, begin to decrease by 1.5% annually. Without applications to increase collagen synthesis, there is an 80% reduction by age 70. Over time, our skin looks more dull and matte, fine lines and sagging begin to appear in the mid-30s. These fine lines and sagging become more pronounced over the years. The triangular structure of the face gradually disappears, and the lower part of the face begins to widen. The Spider Web Technique helps solve possible problems in the skin as an alternative to aesthetic surgery, a non-surgical facial rejuvenation application. Spider Web Aesthetics takes inspiration from nature, from the magnificent shape, durability, and superior mechanism of the spider web, which has the strongest and most flexible structure.'
                }
            ]
        },
        accordeon: [
            {
                title: 'What is Rejuvenation with the Spider Web Technique?',
                description: '<p style="text-align:justify">In the spider web aesthetics application, fine-tipped needles are used to place these bio-compatible, completely absorbable threads under the skin. They are then placed into the subcutaneous tissue in a web-like pattern. This process both provides immediate lifting by mechanically tightening the tissue and gradually stimulates collagen and elastin production, resulting in a longer-lasting rejuvenation effect. The application can be comfortably performed in an office setting under local anesthesia. It allows for a more youthful, firm skin appearance without surgery. Additionally, the threads stimulate the lymphatic system, reducing fat deposits and helping to regain the V-line in the face oval, thereby reducing jowls and double chin appearance.</p>'
            },
            {
                title: 'Duration of the Spider Web Technique Procedure?',
                description: '<p style="text-align: justify;">The duration of the procedure depends on the number of threads used. However, commonly performed applications take an average of 45 minutes.</p>'
            },
            {
                title: 'Differences Between Non-Surgical and Surgical Face Lifting?',
                description: '<p style="text-align:justify">Surgical face lifting or non-surgical thread face lifting does not produce permanent results. Surgical face lifting operations show an effect that lasts up to 5 years. However, general anesthesia is required for the application, and this can be challenging, especially for patients with chronic diseases. There is a recovery period after the surgery. The durability of non-surgical thread face lifting methods is 1-2 years. However, the most important advantages are that it can be applied under local anesthesia and allows immediate return to daily life after the application.</p>'
            },
            {
                title: 'How is Face Lifting with Absorbable Threads Done?',
                description: '<ul><li><span style="font-weight:400">The threads used are 100% compatible with the human body</span></li><li><span style="font-weight:400">The procedure duration is 30-45 minutes</span></li><li><span style="font-weight:400">It is painless with appropriate anesthesia methods</span></li><li><span style="font-weight:400">The application is safe</span></li><li><span style="font-weight:400">The effect is seen immediately</span></li><li><span style="font-weight:400">It also stimulates collagen and elastin fiber production, with this effect becoming evident, especially within 3 months</span></li><li><span style="font-weight:400">It can be done in an office setting</span></li><li><span style="font-weight:400">It leaves no scars</span></li><li><span style="font-weight:400">It can be combined with other applications</span></li><li><span style="font-weight:400">It has no side effects</span></li></ul>'
            },
            {
                title: 'Post-Thread Face Lifting Care?',
                description: '<ul><li><span style="font-weight:400">Apply ice immediately after the procedure</span></li><li><span style="font-weight:400">Avoid makeup for at least 24 hours</span></li><li><span style="font-weight:400">Avoid activities that require opening your mouth wide (e.g., eating an apple, laughing out loud) for a week</span></li><li><span style="font-weight:400">Sleep in a high pillow position for about 3-5 nights</span></li><li><span style="font-weight:400">Wash your face gently without rubbing or massaging for 5-7 days</span></li><li><span style="font-weight:400">Avoid excessive face and neck movements for about 2 weeks</span></li><li><span style="font-weight:400">Avoid face and neck massage for a month</span></li><li><span style="font-weight:400">Avoid excessive sun exposure for 2 weeks</span></li><li><span style="font-weight:400">Avoid heavy exercise for 2 weeks</span></li><li><span style="font-weight:400">Do not use the sauna for about 3 weeks</span></li><li><span style="font-weight:400">Avoid dental operations for 3 weeks</span></li></ul>'
            },
            {
                title: 'How Long Does the Thread Face Lifting Procedure Take?',
                description: '<p style="text-align: justify;">The duration of the procedure depends on the number of threads used. However, commonly performed applications take an average of 45 minutes.</p>'
            },
            {
                title: 'Who Should Not Have Thread Face Lifting?',
                description: '<ul><li><span style="font-weight:400">Active infections and herpes in the application area</span></li><li><span style="font-weight:400">Autoimmune diseases</span></li><li><span style="font-weight:400">Keloid tendency</span></li><li><span style="font-weight:400">Neurological and psychological disorders</span></li><li><span style="font-weight:400">Individuals with permanent fillers</span></li><li><span style="font-weight:400">Individuals with bleeding tendencies</span></li><li><span style="font-weight:400">Individuals with severe heart or kidney disease</span></li><li><span style="font-weight:400">Cancer patients</span></li></ul>'
            },
            {
                title: 'Common Complications',
                description: '<span style="font-weight:400"><strong>Edema:</strong> Becomes noticeable in the first 24 hours after treatment and can last up to a week.</span><span style="font-weight:400"><strong>Bruising:</strong> Bruises can develop in the needle entry points within the application areas, especially in eyebrow lifting and around the eyes. They subside within 1-2 weeks.</span><span style="font-weight:400"><strong>Redness:</strong> Redness disappears within 24 hours.</span><span style="font-weight:400"><strong>Tightness and Pain:</strong> Caused by the needles in the application areas.</span>'
            }
        ]
    },
    {
        applicationid: "thread-applications",
        applicationTitle: "Face Lifting Applications",
        id: "brow-lift-and-shaping-with-thread-application",
        image: "/img/applications/iple-olan-uygulamalar/ip-uygulamasi-ile-kas-kaldirma-ve-sekillendirme.jpeg",
        title: "Brow Lift and Shaping with Thread Application",
        content: {
            blogPageTitle: "Brow Lift and Shaping with Thread Application",
            blogPageDesc: "Over time and with gravity, wrinkles and sagging appear all over your face, including your eyebrows and forehead. You may have “frown lines” between your eyebrows that make you look serious or angry, the outer part of your eyebrows may sag, making you look sad, and you may look extra tired due to low eyebrows and eyelids. If you want to look younger, happier, and more energetic, a brow lift may be the right choice for you. Also known as a forehead lift or forehead rejuvenation, a brow lift is a cosmetic procedure performed to elevate the eyebrows. A brow lift improves the appearance of the forehead, eyebrows, and the area around the eyes by raising the soft tissue and skin of the forehead and brow.",
            metaDescription: "Click to get detailed information about lifting and shaping the eyebrow area with thread application, application stages, and results.",
            blogDetailPageInfo: [
                {
                    description: "Over time and with gravity, wrinkles and sagging appear all over your face, including your eyebrows and forehead. You may have 'frown lines' between your eyebrows that make you look serious or angry, the outer part of your eyebrows may sag, making you look sad, and you may look extra tired due to low eyebrows and eyelids. If you want to look younger, happier, and more energetic, a brow lift may be the right choice for you. Also known as a forehead lift or forehead rejuvenation, a brow lift is a cosmetic procedure performed to elevate the eyebrows. A brow lift improves the appearance of the forehead, eyebrows, and the area around the eyes by raising the soft tissue and skin of the forehead and brow.",
                }
            ]
        },
        accordeon: [
            {
                title: "How is Brow Lift with Thread Application Done?",
                description: "<p style=\"text-align:justify\">The brow lift procedure is a minimally invasive application. It lifts the upper eyelids and eyebrows without any incisions. The thread brow lift procedure is performed under local anesthesia. This will reduce any pain or discomfort you may experience. Then, the threads are placed under the skin using a blunt cannula or hollow needle in a procedure that takes 15-20 minutes. It lifts the eyebrows by raising the drooping tissue with the upper eyelid. It also acts as a scaffold to create volume under the skin and increase collagen production. Thus, double benefits are provided. The results are immediately noticeable. And afterwards, the person can immediately return to their social life.</p>"
            },
            {
                title: "How Long Do the Results of the Brow Lift with Thread Application Last?",
                description: "<p style=\"text-align:justify\">The results vary depending on your body's metabolism, lifestyle, activity, and the type of medical-grade thread used in the procedure. The threads used are absorbable. They naturally dissolve in six to nine months. The duration of effectiveness can vary, but it can last for 1.5-2 years.</p>"
            },
            {
                title: "Is the Brow Lift with Thread Application Procedure Safe?",
                description: "<ul><li><span style=\"font-weight:400\">Since the brow lift procedure with thread is non-invasive, it is considered very low risk.</span></li><li><span style=\"font-weight:400\">The threads used for brow lift and shaping are ultra-fine and biologically degradable, suitable for medical use.</span></li><li><span style=\"font-weight:400\">The threads dissolve over time and are naturally metabolized by the body, leaving no trace.</span></li><li><span style=\"font-weight:400\">It is always recommended to have your treatment done by a reliable practitioner with the necessary expertise in thread lifting techniques.</span></li></ul>"
            },
            {
                title: "How Much Does the Brow Lift with Thread Application Cost?",
                description: "<ul><li><span style=\"font-weight:400\">Each procedure is personalized to you and your needs, and thus the cost is also personalized.</span><span style=\"font-weight:400\">We invite you to our clinic for a preliminary consultation as we know that cost is important.</span>"
            }
        ]
    },
    {
        applicationid: "thread-applications",
        applicationTitle: "Face Lifting Applications",
        id: "face-lifting-tightening-with-thread-application",
        image: "/img/applications/iple-olan-uygulamalar/ip-uygulamasi-ile-yuz-askilama-yuz-germe.jpeg",
        title: "Face Lifting/Tightening with Thread Application",
        content: {
            blogPageTitle: "Face Lifting/Tightening with Thread Application",
            blogPageDesc: "It is a procedure based on placing specially produced threads that are compatible with human tissue under the skin with the help of a fine needle. The procedure is performed under local anesthesia. It can be used in all areas of the body. In the field of aesthetic surgery, it is a revolutionary method that can be applied without surgery.",
            metaDescription: "Click to get information about the face lifting technique based on placing specially produced threads under the skin with the help of a fine needle.",
            blogDetailPageInfo: [
                {
                    description: "It is a procedure based on placing specially produced threads that are compatible with human tissue under the skin with the help of a fine needle. The procedure is performed under local anesthesia. It can be used in all areas of the body. In the field of aesthetic surgery, it is a revolutionary method that can be applied without surgery. It shows the effect of rapid healing in the application area. The patient can immediately return to social life and the procedure can be safely applied to both women and men. It has also been found that this application improves skin quality and is effective in rejuvenating the skin by increasing the synthesis of collagen/elastin fibers.",
                }
            ]
        },
        accordeon: [
            {
                title: "For What Purpose Is It Applied to Our Face?",
                description: "<span style=\"font-weight:400\">It can be applied to many areas of the face, neck, and body. Especially;</span><ul><li><span style=\"font-weight:400\">Sagging cheeks,</span></li><li><span style=\"font-weight:400\">Prominence of the nasolabial groove (from the side of the nose to the corner of the mouth),</span></li><li><span style=\"font-weight:400\">Prominence of the marionette line (from the corner of the mouth to the chin),</span></li><li><span style=\"font-weight:400\">Sagging jawline area,</span></li><li><span style=\"font-weight:400\">Forehead lift treatment,</span></li><li><span style=\"font-weight:400\">Successful in neck lift treatment.</span></li><li><span style=\"font-weight:400\">It is one of the ideal treatment methods for sagging upper-inner arm and thigh.</span></li><li><span style=\"font-weight:400\">It also helps improve deformed abdominal skin.</span></li></ul>"
            },
            {
                title: "When Does It Start to Show Its Effect and How Long Does It Last?",
                description: "<p style=\"text-align:justify\">It starts to show its effect immediately after the application. The real lifting effect and skin tightening become noticeable a few weeks later. The effect lasts for about 1.5-2 years.</p>"
            },
            {
                title: "How Is the Face Lifting Procedure with Thread Applied?",
                description: "<ul><li><span style=\"font-weight:400\">First, your face is cleaned. Then local anesthesia is applied to your skin.</span></li><li><span style=\"font-weight:400\">Thread application is made to the marked points in the targeted areas. Over time, the synthesis of collagen and elastin fibers begins to increase in your skin.</span></li><li><span style=\"font-weight:400\">After applying anesthesia to the application area, the physician determines the treatment points and application technique.</span></li><li><span style=\"font-weight:400\">The number of threads used varies completely based on individual differences. In skins with a lot of sagging and looseness, 15-18 threads may be used.</span></li><li><span style=\"font-weight:400\">The effect is seen immediately after the procedure. Over time, the lifting effect becomes more pronounced, and your skin gradually tightens. A few weeks later, the final result becomes fully noticeable, and the effect lasts for 12 to 18 months.</span></li><li><span style=\"font-weight:400\">The duration of the application varies depending on the number of threads used but averages 20 to 30 minutes.</span></li></ul>"
            },
            {
                title: "Advantages of Face Lifting with Thread",
                description: "<ul><li><span style=\"font-weight:400\">The threads used are 100% compatible with the human body.</span></li><li><span style=\"font-weight:400\">The application duration is 20-30 minutes.</span></li><li><span style=\"font-weight:400\">It is painless with appropriate anesthesia methods.</span></li><li><span style=\"font-weight:400\">The application is safe.</span></li><li><span style=\"font-weight:400\">Its effect is seen immediately.</span></li><li><span style=\"font-weight:400\">At the same time, it stimulates the production of collagen and elastin fibers, which becomes especially noticeable within 3 months.</span></li><li><span style=\"font-weight:400\">It can be done in a clinical setting.</span></li><li><span style=\"font-weight:400\">It leaves no scars or marks.</span></li><li><span style=\"font-weight:400\">It can be combined with other applications.</span></li><li><span style=\"font-weight:400\">It has no side effects.</span></li></ul>"
            }
        ]
    },
    {
        applicationid: "mesotherapy-applications",
        applicationTitle: "Mesotherapy Applications",
        id: "factors-affecting-skin-aging",
        image: "/img/applications/mezoterapi-uygulamalari/cildin-yaslanmasini-etkileyen-faktorler.jpg",
        title: "Factors Affecting Skin Aging",
        content: {
            blogPageTitle: "Factors Affecting Skin Aging",
            blogPageDesc: "The skin ages both chronologically due to age and due to external factors. While age-related aging is genetic, external factors include sun rays, smoking, alcohol consumption, poor diet, psychological and physical stress, and environmental pollution.",
            metaDescription: "The skin ages both chronologically due to age and due to external factors. Click for detailed information!",
            blogDetailPageInfo: [
                {
                    description: "The skin ages both chronologically due to age and due to external factors. While age-related aging is genetic, external factors include sun rays, smoking, alcohol consumption, poor diet, psychological and physical stress, and environmental pollution. Chronological aging results show individual differences because it is dependent on the genetic program, and it mainly manifests as thinning of the skin, a decrease in collagen fibrils and elastic fibers under the skin, and a decrease in the number and synthesis capacity of fibroblast cells that regulate skin tissue architecture. One of the most studied hypotheses regarding chronological aging is the free radical theory (oxidative stress theory). Free radicals and reactive oxygen species disrupt the cellular structure, reducing the production capacity of cells and increasing the degradation of existing collagen-elastin fibers in the tissue. While the components in mesotherapy products are well-known for reversing the aging process, these products not only regulate the skin's moisture balance but also contribute to skin rejuvenation by increasing fibroblast activation.",
                },
                {
                    description: "The changes in the skin aging process have been tried to be clinically graded.",
                },
                {
                    title: "According to Glogau;",
                    description: "<table><tbody><tr><td><span style=\"font-weight:400\">Type I</span></td><td><span style=\"font-weight:400\">Minimal wrinkles,</span><span style=\"font-weight:400\">Slight color changes,</span><span style=\"font-weight:400\">No irregularities on the skin,</span><span style=\"font-weight:400\">Patients aged 20-30.</span></td></tr><tr><td><span style=\"font-weight:400\">Type II</span></td><td><span style=\"font-weight:400\">Wrinkles that become prominent with expressions,</span><span style=\"font-weight:400\">Parallel smile lines on the cheeks,</span><span style=\"font-weight:400\">Sun damage starts to become noticeable,</span><span style=\"font-weight:400\">Irregularities on the skin that can be felt by touch,</span><span style=\"font-weight:400\">Patients aged 30 to 40.</span></td></tr><tr><td><span style=\"font-weight:400\">Type III</span></td><td><span style=\"font-weight:400\">Static wrinkles (wrinkles visible even when the face is not moving),</span><span style=\"font-weight:400\">Sun spots,</span><span style=\"font-weight:400\">Visible irregularities on the skin,</span><span style=\"font-weight:400\">Patients aged 50 to 60.</span></td></tr><tr><td><span style=\"font-weight:400\">Type IV</span></td><td><span style=\"font-weight:400\">Wrinkles throughout the face.</span></td></tr></tbody></table>",
                },
                {
                    title: "According to Rubin;",
                    description: "<table><tbody><tr><td><span style=\"font-weight:400\">Level I</span></td><td><span style=\"font-weight:400\">Wrinkles that become prominent during muscle contractions,</span><span style=\"font-weight:400\">Dull skin with reduced brightness.</span></td></tr><tr><td><span style=\"font-weight:400\">Level II</span></td><td><span style=\"font-weight:400\">Expression wrinkles and wrinkles noticeable at rest,</span><span style=\"font-weight:400\">Uneven skin tone, dilation of capillaries.</span></td></tr><tr><td><span style=\"font-weight:400\">Level III</span></td><td><span style=\"font-weight:400\">Deep wrinkles throughout the face,</span><span style=\"font-weight:400\">Thickening, yellowing, loss of elasticity, formation of senile keratosis and actinic keratosis known as age spots.</span></td></tr></tbody></table>",
                },
                {
                    description: "<table><tbody><tr><td><b>The ideal young neck is:</b><span style=\"font-weight:400\"> A neck with good skin tone, a chin-neck angle of 90°-105°, a defined jawline, no pronounced neck lines, and no fat formation in the double chin area.</span></td></tr></tbody></table>",
                }
            ]
        },
    },
    {
        applicationid: "mesotherapy-applications",
        applicationTitle: "Mesotherapy Applications",
        id: "description-of-mesotherapy-applications",
        image: "/img/applications/mezoterapi-uygulamalari/mezoterapi-uygulamalarinin-tanimi.jpg",
        title: "Description of Mesotherapy Applications",
        content: {
            blogPageTitle: "Description of Mesotherapy Applications",
            blogPageDesc: "Mesotherapy is simply the application of products used for treatment and cosmetic purposes directly to problem areas in small amounts using needles. In this method, the general absorption of the products by the body is very low.",
            metaDescription: "We can simply define mesotherapy as the injection of products used for treatment and cosmetic purposes directly to problem areas.",
            blogDetailPageInfo: [
                {
                    description: "Mesotherapy is simply the application of products used for treatment and cosmetic purposes directly to problem areas in small amounts using needles. In this method, the general absorption of the products by the body is very low, so drug-related side effects are minimized in treatment. The number of injections and applications made depends on the patient, the purpose, and the anatomy of the area to be injected.",
                },
                {
                    description: "The most common side effects that can occur after mesotherapy are swelling, bruising, itching, and pain. However, these side effects are usually temporary.",
                },
                {
                    title: "For What Purpose is Mesotherapy Applied:",
                    description: "Mesotherapy is a treatment method that provides many different benefits depending on the application area. In the areas where it is applied;",
                },
                {
                    description: "1. To reduce the signs of aging in the face-neck-décolleté area,",
                },
                {
                    description: "2. For the treatment of sun spots-skin spots,",
                },
                {
                    description: "3. To address skin problems,",
                },
                {
                    description: "4. For hair loss,",
                },
                {
                    description: "5. For regional fat reduction,",
                },
                {
                    description: "6. To address problems like stretch marks and cellulite, mesotherapy can be applied.",
                },
                {
                    description: "Depending on the purpose of the application, for example; for facial rejuvenation or spot or cellulite treatment, the product combinations used in mesotherapy solutions are different. With mesotherapy applications, we select the appropriate mesotherapy products according to the purpose. This way, treatment effectiveness is increased with targeted therapy.",
                }
            ]
        },
    },
    {
        applicationid: "mesotherapy-applications",
        applicationTitle: "Mesotherapy Applications",
        id: "facial-mesotherapy",
        image: "/img/applications/mezoterapi-uygulamalari/yuz-mezoterapisi.jpg",
        title: "Facial Mesotherapy",
        content: {
            blogPageTitle: "Facial Mesotherapy",
            blogPageDesc: "The purpose of face, neck, and décolleté mesotherapy is to increase the synthesis capacity of fibroblasts, which constitute the skin tissue architecture, through the renewal and stimulation of cellular activity, and to stimulate the production of new collagen-elastin fibers and hyaluronic acid, thereby increasing the moisture, brightness, and firmness of the skin.",
            metaDescription: "The purpose of face, neck, and décolleté mesotherapy is to strengthen the skin tissue architecture by renewing and stimulating cellular activity. Click for more information...",
            blogDetailPageInfo: [
                {
                    description: "The purpose of face, neck, and décolleté mesotherapy is to increase the synthesis capacity of fibroblasts, which constitute the skin tissue architecture, through the renewal and stimulation of cellular activity, and to stimulate the production of new collagen-elastin fibers and hyaluronic acid, thereby increasing the moisture, brightness, and firmness of the skin. Facial mesotherapy aims for Biorejuvenation (stimulating the skin) and Mesolift (lifting the skin).",
                },
                {
                    description: "While mesotherapy applications are sufficient to stimulate the skin in the 20s, it needs to be applied together with device procedures after the 30s. Mesotherapy is an application that can be performed at any age to support and reconstruct the skin.",
                },
                {
                    title: "Who Can Have Facial Mesotherapy and What Are Its Benefits?",
                    description: "1. The reduction of aging signs (wrinkles, dullness, and spots) caused by the decrease of collagen-elastin fibers and hyaluronic acid in the face, neck, décolleté area, and hands due to sun damage and aging process. With mesotherapy, you can achieve a vibrant, healthy, and bright appearance for dull, lifeless, pale, and tired-looking skin.",
                },
                {
                    description: "2. For skin problems such as puffiness, dark circles, and bags under the eyes,",
                },
                {
                    description: "3. To reduce the appearance of wrinkles caused by aging and excessive facial expressions,",
                },
                {
                    description: "4. To prevent skin sagging in areas that sag easily with age, such as the neck and décolleté, and to restore the elasticity of the subcutaneous tissue,",
                },
                {
                    description: "5. To reduce scars on the skin, stretch marks, and all kinds of spots on the skin surface,",
                },
                {
                    description: "6. To correct uneven skin tone.",
                },
                {
                    title: "General Ingredients in Mesotherapy Products for Skin Rejuvenation",
                    description: "There are many mesotherapy products used for skin rejuvenation. Although the compositions of the products vary, most products contain vitamins, amino acids, minerals, co-enzymes, growth factors, antioxidants, and hyaluronic acid as general ingredients. Depending on the variety of the product, new ingredients can be added to these contents. You can ask your doctor for information about the content while they perform the mesotherapy application on you, as the components and their concentrations vary in mesotherapy products.",
                },
                {
                    title: "Generally Found Products:",
                },
                {
                    title: "Vitamins and Derivatives:",
                    description: "Vitamins have important functions related to the skin aging process. They have antioxidant functions and regulate cellular metabolism by increasing collagen synthesis.",
                },
                {
                    title: "Vitamin A",
                    description: "Vitamin A has antioxidant effects that regulate epidermal regeneration, melanocyte activity, and control sebaceous gland activity. Along with Vitamin C, it helps in the synthesis of components that provide collagen and skin structural support.",
                },
                {
                    title: "B Vitamins",
                    description: "B1 vitamin, Vitamin B2, B3 vitamin, B5 vitamin, Vitamin B6, B7 vitamin, B9 vitamin, B12 vitamins primarily play roles in the control of cell function.",
                },
                {
                    title: "Vitamin C",
                    description: "Vitamin C is an essential antioxidant necessary for collagen synthesis.",
                },
                {
                    title: "Vitamin E",
                    description: "Vitamin E is another important component with high antioxidant activity. It contributes to skin regeneration and speeds up wound healing in case of skin damage.",
                },
                {
                    title: "Vitamin K",
                    description: "Vitamin K is effective in regulating blood circulation.",
                },
                {
                    title: "Amino Acids and Related Compounds:",
                    description: "Amino acids are primarily necessary for the synthesis of collagen, elastin, and hyaluronic acid, which support the skin.",
                },
                {
                    title: "Minerals, Coenzymes:",
                    description: "Minerals and coenzymes are necessary for the synthesis of components that support the skin.",
                },
                {
                    title: "Hyaluronic Acid (HA):",
                    description: "Hyaluronic acid is a glycosaminoglycan polysaccharide and a main component of connective tissue. It is produced by fibroblasts and released into the extracellular space. In the skin, it supports the intercellular space, hydrates the skin due to its water-binding property, and supports the normal functions of cells. HA has the capacity to bind water up to 1000 times its volume. The reason for the skin's dryness as age progresses is the decreased production of hyaluronic acid.",
                },
                {
                    title: "Frequency of Mesotherapy Application:",
                    description: "The frequency of treatment largely depends on patient compliance, financial situation, and pre-treatment skin assessment. Treatment is initially performed at 2-week intervals for 3-4 sessions and then at intervals of 2-3 months.",
                },
                {
                    title: "What Should Be Considered After Mesotherapy Application:",
                    description: "Although rare, temporary bruising due to subcutaneous bleeding may occur in the injection area. Since the absorption of drugs in mesotherapy into the body is minimal, its side effects are almost negligible, and there are no significant reported side effects in treatments.",
                },
                {
                    description: "After the application, the person can immediately return to their normal routine. It is recommended not to take a bath for 24 hours after the application.",
                }
            ]
        },
    },
    {
        applicationid: "mesotherapy-applications",
        applicationTitle: "Mesotherapy Applications",
        id: "salmon-dna-mesotherapy",
        image: "/img/applications/mezoterapi-uygulamalari/somon-dna-mezoterapisi-nedir.jpg",
        title: "What is Salmon DNA Mesotherapy?",
        content: {
            blogPageTitle: "What is Salmon DNA Mesotherapy?",
            blogPageDesc: "Salmon DNA treatment, which is very popular today, is one of the skin rejuvenation methods where pure hyaluronic acid and polynucleotides obtained from salmon are used to renew cells in the skin. With its rapid revitalization and rejuvenation effect, it is widely demanded.",
            metaDescription: "Salmon DNA treatment, which is very popular today, is widely demanded for its rapid revitalization and rejuvenation effect. Click for more information...",
            blogDetailPageInfo: [
                {
                    description: "Salmon DNA treatment, which is very popular today, is one of the skin rejuvenation methods where pure hyaluronic acid and polynucleotides obtained from salmon are used to renew cells in the skin. With its rapid revitalization and rejuvenation effect, it is widely demanded.",
                },
                {
                    description: "It is a very popular method for skin rejuvenation because it can be applied in every season and is a practical method.",
                }
            ]
        },
        accordeon: [
            {
                title: "For What Purpose is Salmon DNA Applied:",
                description: "<p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>1.</strong>To restore the moisture and brightness of the skin that has lost its vitality due to environmental factors, dryness, and dullness,</span></p><p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>2.</strong>To restore the vitality of the skin lost due to smoking, alcohol, and intense stress,</span></p><p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>3.</strong>To provide the necessary moisture for the skin that has dried and lost its moisture balance,</span></p><p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>4.</strong>To eliminate wrinkles on the face, neck, and hands during the aging process,</span></p><p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>5.</strong>To reduce dark circles under the eyes and brighten the under-eye area,</span></p><p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>6.</strong>To treat acne scars,</span></p><p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>7.</strong>To improve the appearance of skin stretch marks,</span></p><p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>8.</strong>To tighten regional sagging,</span></p><p style=\"padding-left:40px\"><span style=\"font-weight:400\"><strong>9.</strong>To eliminate sagging seen in the inner arms and knees.</span></p>",
            },
            {
                title: "What Are the Benefits of Salmon DNA Mesotherapy?",
                description: "<p style=\"text-align:justify\">Recently, Salmon DNA treatment has become very popular due to its many proven benefits in clinical trials, making it a highly reliable and effective method. Salmon DNA increases the synthesis of collagen and elastin fibers in the skin structure. This way, the skin quickly recovers and tightens. There is a visible improvement in skin sagging. Significant reductions in wrinkles, especially around the eyes, known as crow's feet, are observed over time.</p>",
            },
            {
                title: "What Should Be Considered After Salmon DNA Application?",
                description: "<ul><li><span style=\"font-weight:400\">It is important to use sunscreen after the application.</span></li><li><span style=\"font-weight:400\">Procedures that peel the skin should be avoided for one week after the application.</span></li><li><span style=\"font-weight:400\">Avoid contact with water for 24 hours after the procedure.</span></li></ul>",
            },
            {
                title: "How Often and How Many Sessions Should Salmon DNA Application Be?",
                description: "<p style=\"text-align:justify\"><span style=\"font-weight:400\">After local anesthesia is applied, the skin is cleaned with an antiseptic solution and the application is performed, which takes about 15-20 minutes. The frequency of application is determined according to the skin's needs. Generally, it is planned as 4-5 sessions at 2-week intervals. The number and interval of sessions may vary depending on the skin condition, the patient's age, and the purpose of the application. Longer sessions may be applied for older and more damaged skin.</span></p>",
            },
            {
                title: "When Does SALMON DNA Show Its Effect?",
                description: "<p style=\"font-weight:400; text-align: justify;\">With Salmon DNA treatment, you will feel the change and vitality in your skin after each application. The main effect will be seen after 3-4 sessions. The improvement in the skin continues with the increase in collagen production for months after the treatment is completed.</p>",
            }
        ]
    },
    {
        applicationid: "mesotherapy-applications",
        applicationTitle: "Mesotherapy Applications",
        id: "spot-mesotherapy",
        image: "/img/applications/mezoterapi-uygulamalari/leke-mezoterapisi.jpg",
        title: "Spot Mesotherapy",
        content: {
            blogPageTitle: "Spot Mesotherapy",
            blogPageDesc: "How Common Are Facial Spots (Melasma)? It usually starts between the ages of 20-40 and is observed frequently enough to affect 40-50% of women. Facial spots are a very common skin disorder, especially among pregnant women.",
            metaDescription: "Spot mesotherapy is a treatment performed by injecting some spot lightening active ingredients under the skin of the spotted area.",
            blogDetailPageInfo: []
        },
        accordeon: [
            {
                title: 'How Common Are Facial Spots (Melasma)?',
                description: '<span style="font-weight: 400; text-align: justify;">It usually starts between the ages of 20-40 and is observed frequently enough to affect 40-50% of women. Facial spots are a very common skin disorder, especially among pregnant women. It affects 40-70% of pregnant women.</span>'
            },
            {
                title: 'Mesotherapy Treatment Against Skin Spots',
                description: '<p style="font-weight:400;text-align:justify">Today, spot treatment with mesotherapy has become one of the frequently preferred methods. It is a safe and highly effective treatment, especially in preventing the prominence of spots that develop due to sun exposure in the summer or in reducing prominent spots.</p>'
            },
            {
                title: 'How Many Sessions Can Spot Treatment with Mesotherapy Be Applied?',
                description: '<ul><li><span style="font-weight:400">An anesthetic cream is applied to the skin before the procedure to ensure that the patient does not feel any pain.</span></li><li><span style="font-weight:400">The application area is cleaned with a local antiseptic.</span></li><li><span style="font-weight:400">After the injection process is completed, the patient can immediately return to daily life.</span></li></ul>&nbsp;<p style="font-weight:400;text-align:justify">The number of sessions of mesotherapy performed for spot problems varies depending on individual differences. The depth of the spots on the skin, the appearance of the spot, and the skin structure of the person can differ.</p><span style="font-weight:400">It can often be said that successful results can be achieved with 4-6 sessions. However, sometimes only 2-3 sessions are sufficient, while rarely 8 sessions may be necessary. The intervals between sessions in spot treatment with mesotherapy are set at 2-3 weeks. After the spot is lightened, maintenance treatment can continue with mesotherapy every 2 months.</span>'
            },
            {
                title: 'When Are Results Seen in Spot Treatment with Mesotherapy?',
                description: '<p style="text-align:justify"><span style="font-weight:400">Most patients expect the spots to disappear immediately after the procedure. However, this is not possible. At least 2 sessions must be completed to see the effects of the mesotherapy method in spot treatment.</span></p>'
            },
            {
                title: 'What Are the Side Effects of Spot Treatment with Mesotherapy?',
                description: '<p style="font-weight:400;text-align:justify">Mild redness and bruising may occur on the skin after mesotherapy application in spot treatment. However, it is important to note that this effect is temporary. Even if spot treatment is done, the person must use sunscreen very regularly.</p>'
            },
            {
                title: 'How Should You Use Sunscreen?',
                description: '<span style="font-weight:400">No matter which spot treatment you have, it is essential to use sunscreen. The sun is a one-way ticket to spot formation.</span><ul><li style="font-weight:400" aria-level="1"><span style="font-weight:400">Use a sunscreen with at least SPF 30 (using sunscreen with SPF 50 or higher does not provide additional benefits).</span></li><li style="font-weight:400" aria-level="1"><span style="font-weight:400">Choose a sunscreen that contains zinc oxide and/or titanium dioxide. If your sunscreen contains iron oxide, it will provide effective protection against the blue light spectrum.</span></li><li style="font-weight:400" aria-level="1"><span style="font-weight:400">Apply your sunscreen half an hour before going outside and make sure to reapply it every 3-4 hours.</span></li><li style="font-weight:400" aria-level="1"><span style="font-weight:400">If you are exposed to sources of blue light such as computer screens or phone screens, do not forget to use sunscreen even indoors.</span></li><li style="font-weight:400" aria-level="1"><span style="font-weight:400">Remember, the evening sun increases your spotting more, so continue using it until the sun sets. Glass does not provide protection from the sun. Do not forget to use sunscreen while driving.</span></li></ul>'
            }
        ]
    },
    {
        applicationid: "mesotherapy-applications",
        applicationTitle: "Mesotherapy Applications",
        id: "liquid-facelift",
        image: "/img/applications/mezoterapi-uygulamalari/mineral-asi-sivi-yuz-germe.jpg",
        title: "Liquid Facelift",
        content: {
            blogPageTitle: "Liquid Facelift",
            blogPageDesc: "What is Liquid Facelift? Fillers that stimulate collagen synthesis and create a volume effect are defined as 'smart fillers'. Smart fillers are used both for filling purposes and in their more diluted form for remodeling activity by increasing the production of new collagen and elastin in the skin.",
            metaDescription: "What is Mineral Vaccine (Liquid Facelift)? What are the Benefits of Mineral Vaccine (Liquid Facelift) to the Skin? Does Mineral Vaccine Reduce Wrinkles and Sagging?",
            blogDetailPageInfo: []
        },
        accordeon: [
            {
                title: 'What is Liquid Facelift?',
                description: '<p style="font-weight:400;text-align:justify">Fillers that stimulate collagen synthesis and create a volume effect are defined as "smart fillers". Smart fillers are used both for filling purposes and in their more diluted form for remodeling activity by increasing the production of new collagen and elastin in the skin.</p>'
            },
            {
                title: 'Who Can Have Liquid Facelift?',
                description: '<ul><li style="font-weight:400" aria-level="1"><span style="font-weight:400">It can be easily applied in all areas with significant loss of elasticity, fine lines, and sagging. Ideal application areas include the face, neck, chest, abdomen, hips, arms, elbows, and knees.</span></li></ul><span style="font-weight:400"></span><span style="font-weight:400">For all wrinkle signs and sagging on the face,</span><span style="font-weight:400">For neck wrinkles,</span><span style="font-weight:400">For spots and wrinkles in the décolleté area, </span><ul><li style="font-weight:400" aria-level="1"><span style="font-weight:400">For dull, pale, and dehydrated skin</span></li><li style="font-weight:400" aria-level="1"><span style="font-weight:400">For those in their mid-30s who want to restructure their skin and improve skin quality, and reduce the formation of fine lines and sagging,</span></li><li style="font-weight:400" aria-level="1"><span style="font-weight:400">For spots, wrinkles, and volume loss on the hands,</span></li><li style="font-weight:400" aria-level="1"><span style="font-weight:400">For reducing sagging or skin folds on the elbows and knees due to age or loss of elasticity,</span></li><li style="font-weight:400" aria-level="1"><span style="font-weight:400">For treating cellulite and eliminating the dimpled appearance in cellulite areas.</span></li></ul>'
            },
            {
                title: 'How Is Liquid Facelift Applied?',
                description: '<ul><li><span style="font-weight:400">Before the application, the dilution ratio is determined based on the structure of your skin and the application area. Different dilution ratios exist for the neck, face, or areas with thick skin versus thin skin. Appropriate dilution ratios are determined according to your skin condition.</span></li><li><span style="font-weight:400">After cleaning the application area, the product is applied under the skin.</span></li><li aria-level="1"><span style="font-weight:400">The average application time is about 30 minutes.</span></li></ul>'
            },
            {
                title: 'When Can Liquid Facelift Be Applied?',
                description: '<p style="text-align:justify"><span style="font-weight:400">Liquid facelift can be applied in any season.</span></p>'
            },
            {
                title: 'How Many Sessions Is Liquid Facelift?',
                description: '<p style="font-weight:400;text-align:justify">Liquid facelift is frequently applied in a single session. Protocols are also available for 1-3 sessions at intervals of 1-3 months, depending on your skin condition.</p>'
            },
            {
                title: 'Is There Any Allergy After Liquid Facelift?',
                description: '<p style="font-weight:400;text-align:justify">The product used for liquid facelift is an FDA-approved CaHA biocompatible, biodegradable, and absorbable biostimulator material. Therefore, you will not have an allergy. However, as with any procedure, you may experience swelling for 2-3 days after the application.</p>'
            },
            {
                title: 'When Does the Effectiveness of Liquid Facelift Begin and How Long Does It Last?',
                description: '<p style="font-weight:400;text-align:justify">The effect of the liquid facelift application is noticeable for a long time by everyone. The applied areas become tighter and more elastic. It significantly improves and rejuvenates the skin structure and makes it smoother. The stimulating effect on the skin becomes noticeable 2 months after the liquid facelift application, and it stimulates the synthesis of new collagen-elastin fibers in the skin. The FDA-approved CaHA biocompatible, biodegradable, and absorbable biostimulator material used in liquid facelift is a unique product that provides collagen biostimulation. The average residence time in tissues is 9-18 months (duration of effect).</p>'
            },
            {
                title: 'What Should Be Considered After Liquid Facelift?',
                description: '<span style="font-weight:400">After all procedures applied to your skin, processes that can cause skin irritation, such as scrubbing or peeling, should not be done for 2 weeks. Activities with intense steam and heat, such as saunas and baths, should be avoided. The treated area must be protected from the sun.</span>'
            },
            {
                title: 'Is Liquid Facelift Permanent?',
                description: '<span style="font-weight:400">As with other medical aesthetics and skin rejuvenation applications, it is important to repeat the liquid facelift application every 1-1.5 years for the continuity of the treatment.</span>'
            }
        ],
        footer: '<p style="text-align:right"><span style="font-weight:400">Assoc. Prof. Ebru Karagün</span></p><p style="text-align:right"><span style="font-weight:400">Dermatology Specialist</span></p><p style="text-align:center"><strong>A woman is as old as she deserves (Coco Chanel)</strong></p><p style="text-align:center"><strong>Life always begins from where we catch it</strong></p>'
    },
    {
        applicationid: "mesotherapy-applications",
        applicationTitle: "Mesotherapy Applications",
        id: "youth-mesotherapy",
        image: "/img/applications/mezoterapi-uygulamalari/genclik-asisi-nedir.jpg",
        title: "What is Youth Mesotherapy?",
        content: {
            blogPageTitle: "What is Youth Mesotherapy?",
            blogPageDesc: "Youth Mesotherapy is a mesotherapy procedure that eliminates sagging, fine lines causing wrinkles, spots, insufficient moisture, and pale skin color that occur with aging. During the aging process, we lose 2.5% of the collagen-elastin fibers in our skin each year, and in our 30s, fine lines, wrinkles, sagging, and dull skin begin to form. In recent years, thanks to applications with anti-aging effects, collagen-elastin and hyaluronic acid production under the skin is increased, and signs of aging are minimized. Youth mesotherapies, which address wrinkles, sagging, and dull skin color issues, can be applied to all skin types today.",
            metaDescription: "What is Youth Mesotherapy? What Are the Benefits of Youth Injections to the Skin? Do Youth Injections Rejuvenate the Skin? Click for details!",
            blogDetailPageInfo: [
                {
                    description: "Youth Mesotherapy is a mesotherapy procedure that eliminates sagging, fine lines causing wrinkles, spots, insufficient moisture, and pale skin color that occur with aging. During the aging process, we lose 2.5% of the collagen-elastin fibers in our skin each year, and in our 30s, fine lines, wrinkles, sagging, and dull skin begin to form. In recent years, thanks to applications with anti-aging effects, collagen-elastin and hyaluronic acid production under the skin is increased, and signs of aging are minimized. Youth mesotherapies, which address wrinkles, sagging, and dull skin color issues, can be applied to all skin types today.",
                },
                {
                    title: "What Are the Ingredients in Youth Mesotherapies:",
                },
                {
                    title: "Salmon DNA:",
                    description: "The youth elixir obtained from salmon helps renew cells and address underlying skin issues. Thus, with its anti-aging effect, it supports the restoration of lost moisture and collagen-elastin fibers in the skin due to aging.",
                },
                {
                    title: "Hyaluronic Acid:",
                    description: "It restores the wrinkles and lost moisture in the skin caused by aging and environmental factors.",
                },
                {
                    title: "Collagen Injections:",
                    description: "They play an effective role in smoothing wrinkles and naturally filling and tightening the skin.",
                },
                {
                    title: "Vitamins, Minerals, and Amino Acids:",
                    description: "They are the building blocks necessary for the production of collagen-elastin fibers, have antioxidant effects, and thus help structure the skin and play an effective role in delaying the aging process.",
                },
                {
                    title: "Niacinamide:",
                    description: "It has antioxidant effects, balances skin moisture, and most importantly, is effective in reducing spots.",
                },
                {
                    title: "Growth Factors:",
                    description: "They have significant stimulating and structuring effects on the skin. Hexapeptide-8 is one of the most important growth factors.",
                },
                {
                    title: "Pentapeptide-18 and DMAE:",
                    description: "They have effects on muscle tone. They are effective in reducing fine lines, tightening, and reducing sagging.",
                },
                {
                    description: "Products containing different combinations of these ingredients are available. After evaluating your skin, it will be determined which youth mesotherapy is suitable for you.",
                },
            ]
        },
        accordeon: [
            {
                title: "In Which Situations Is It Applied?",
                description: "<span style=\"font-weight:400\">Youth mesotherapy, which can be applied to individuals of all ages, is generally used from the 30s onwards.</span><span style=\"font-weight:400\">Youth mesotherapies can be applied to the face area, neck-décolleté area, hands, and hair area.</span><span style=\"font-weight:400\">For all wrinkle signs and sagging on the face,</span><span style=\"font-weight:400\">For neck wrinkles,</span><span style=\"font-weight:400\">For spots and wrinkles in the décolleté area, </span><span style=\"font-weight:400\">For spots, wrinkles, and volume loss on the hands,</span><span style=\"font-weight:400\">For dull and lifeless skin color,</span><span style=\"font-weight:400\">Additionally, for protecting the skin in individuals who smoke, live a stressful life, have insufficient sleep, eat irregularly (a diet containing refined sugar and trans fats), or do not use sunscreen adequately.</span>"
            },
            {
                title: "How Is Youth Mesotherapy Applied?",
                description: "<span style=\"font-weight:400\">Your skin will be examined to determine which options are suitable for you.</span><span style=\"font-weight:400\">A local anesthetic cream is applied to the area to be treated before the procedure, and after cleaning the area, the procedure begins. Youth injections are applied under the skin with a needle.</span><span style=\"font-weight:400\">You can continue your routine without interruption after the procedure.</span>"
            },
            {
                title: "When Is Youth Mesotherapy Done?",
                description: "<span style=\"font-weight:400\">Youth mesotherapy can be applied in any season.</span>"
            },
            {
                title: "How Many Sessions of Youth Mesotherapy Are Applied?",
                description: "<p style=\"text-align: justify;\"><span style=\"font-weight: 400;\">Youth mesotherapy should be done in 3-4 sessions at intervals of 2-3 weeks. </span></p>"
            },
            {
                title: "What Are the Side Effects of Youth Mesotherapy?",
                description: "<span style=\"font-weight:400\">Mild redness and bruising may occur immediately after the procedure in the treated area. Redness usually resolves on its own within the day after the procedure.</span>"
            },]
    },
    {
        applicationid: "device-procedures",
        applicationTitle: "Device Procedures",
        id: "bbl-laser",
        image: "/img/applications/cihaz-islemleri/bbl-lazer.jpg",
        title: "BBL Laser",
        content: {
            "blogPageTitle": "BBL Laser",
            "blogPageDesc": "BBL is a term often referred to as 'Broad Band Light,' a skin treatment method. BBL Laser is a next-generation laser treatment that targets facial pigments and redness, brightening the skin and effectively addressing various skin issues.",
            "metaDescription": "BBL Laser is an FDA-approved treatment that evens skin tone, reduces pigmentation and redness, rejuvenates the skin, and tightens pores. Learn more about this effective method for skin renewal and acne treatment by reading the blog post!",
            "blogDetailPageInfo": [
                {
                    "title": "WHAT IS BBL LASER?",
                    "description": "'BBL' is often referred to as 'Broad Band Light,' a term related to a skin treatment method. BBL Laser is a next-generation laser treatment that targets facial pigments and redness, brightens the skin, and effectively addresses various skin issues."
                },
                {
                    "description": "It is a technology that targets skin problems such as dilated capillaries, red face treatment, rosacea treatment, skin rejuvenation, skin tone balancing, Lentigo (age spots), freckles, and sunspots. It also stimulates collagen production and offers pore-tightening solutions."
                },
                {
                    "description": "With 9 different filters, it provides successful results in treating many skin problems."
                },
                {
                    "description": "Thanks to these features, BBL Laser is widely used worldwide and is an FDA-approved system."
                },
                {
                    "title": "How Does BBL; Broad Band Light Work?",
                    "description": "<ul><li>Pigmentation and Skin Tone Balancing: BBL delivers a broad spectrum of light to the skin. This light targets specific colors, such as melanin (pigment), effectively treating freckles, age spots (lentigo), and sunspots.</li><li>Redness and Skin Tone Balancing: BBL delivers a broad spectrum of light to the skin. This light targets specific colors, such as red blood cells, making it effective for redness, rosacea-related redness, post-acne red marks, and vascular blemishes.</li><li>Pore Tightening: By regulating sebum (oil) secretion and increasing elastin production, it helps tighten pores.</li><li>Skin Renewal and Collagen & Elastin Production: BBL delivers energy to the upper layers of the skin, causing microscopic damage. This prompts the skin to initiate its natural healing processes, aiding in the restructuring of skin cells, boosting collagen and elastin production, and rejuvenating skin texture. Collagen and elastin are proteins that ensure skin firmness and elasticity. Increased production leads to firmer, brighter, and more vibrant skin.</li><li>Acne Treatment: BBL can also be used for acne treatment. The light penetrates the lower layers of the skin, helping to reduce bacteria that cause acne and control inflammation, preventing future acne outbreaks.</li></ul>"
                },
                {
                    "description": "The effectiveness of the treatment may vary depending on the device's features, the treatment area, and the individual's skin type. A pre-treatment evaluation by your doctor is crucial to determining a treatment plan suitable for you."
                },
                {
                    "title": "In Which Cases is BBL; Broad Band Light Used?",
                    "description": "Broad Band Light (BBL) is a laser technology used to treat skin problems with a wide spectrum. Below are common cases where BBL is applied:"
                },
                {
                    "description": "<ul><li>Pigmentation Problems: BBL treats freckles, age spots (lentigo), sunspots, and congenital marks.</li><li>Facial Redness: It is effective for dilated veins, capillaries, skin redness, and vascular issues caused by rosacea.</li><li>Skin Rejuvenation: BBL can be used for skin rejuvenation by increasing collagen and elastin production, improving skin firmness and elasticity, and reducing fine lines and wrinkles.</li><li>Acne Treatment and Post-Acne Red Marks: BBL treats red marks left after acne and inflamed acne itself.</li><li>Skin Tone Irregularities: BBL is effective for skin tone irregularities, reddish spots, and brown spots.</li></ul>"
                },
                {
                    "description": "Given these conditions, each individual's skin type and condition are different. Therefore, consulting a specialist before undergoing BBL or similar skin treatments is essential."
                },
                {
                    "title": "On Which Areas Can BBL; Broad Band Light Be Applied?",
                    "description": "Broad Band Light (BBL) treatment can generally be applied to various parts of the body. The areas where the treatment is applied may vary depending on individual needs and skin issues. Common areas include:"
                },
                {
                    "description": "Face: BBL treatment can be applied for skin rejuvenation, brown-pigmentation spots (freckles, age spots, sunspots), capillaries, facial redness, post-acne red marks, skin tone irregularities, and large pores."
                },
                {
                    "description": "Neck: Along with the face, BBL can also treat brown-pigmentation spots (freckles, age spots), and capillaries in the neck area."
                },
                {
                    "description": "Hands: BBL can be used to treat age spots, sun damage, and fine lines on the hands."
                },
                {
                    "description": "Back and Chest-Decollete: BBL treatment can address issues such as acne scars, skin tone irregularities, and sun damage in the back and chest area."
                },
                {
                    "description": "Beyond these areas, BBL can be applied to other regions as well. However, the treatment areas depend on the individual's skin type, problems, and goals. Consulting a specialist before the treatment helps determine a plan tailored to personal needs."
                },
                {
                    "title": "How is BBL; Broad Band Light Applied?",
                    "description": "Broad Band Light (BBL) is a laser treatment method that must be applied by a healthcare professional."
                },
                {
                    "title": "General Application Process:",
                    "description": "Before starting the treatment, the doctor conducts a preliminary examination to assess the patient's skin type, skin problems, and treatment goals. This helps determine suitable treatment parameters and personalize the treatment plan."
                },
                {
                    "description": "After the person's face is cleansed, goggles that completely cover the eyes are applied during the procedure."
                },
                {
                    "description": "A gel is applied to the treatment area to relax the skin and allow the light to penetrate more effectively. Using special applicators, a broad spectrum of light targets specific skin concerns. Each skin problem requires different filters. The application targets problematic areas on the skin surface, addressing pigmentation spots, vascular blemishes, and other skin issues."
                },
                {
                    "description": "This procedure typically takes about 30-40 minutes. However, the duration may vary depending on the size of the treatment area and the treatment goals."
                },
                {
                    "title": "What to Consider in Post-Treatment Care?",
                    "description": "After the treatment, protecting the skin from the sun is essential. Temporary side effects, such as mild redness or swelling, are normal and will subside over time. BBL treatment is usually planned for three to four sessions. Results will gradually appear as the skin heals."
                },
                {
                    "description": "The application is limited during the summer months."
                },
                {
                    "title": "Recovery Process After BBL Treatment",
                    "description": "The next day, you can apply makeup, exercise, return to work, and resume most of your activities immediately."
                },
                {
                    "description": "You may notice some redness that will subside within a few hours. Brown spots may darken and crust before fading and peeling away over time."
                }
            ]
        },
    },
    {
        applicationid: "device-procedures",
        applicationTitle: "Device Procedures",
        id: "endolift",
        image: "/img/applications/cihaz-islemleri/endolift.jpg",
        title: "ENDOLIFT (Non-Surgical Laser Facelift)",
        content: {
            blogPageTitle: "ENDOLIFT (Non-Surgical Laser Facelift)",
            blogPageDesc: "ENDOlift is a combined laser application that offers skin renewal and facial contouring together. This application, which does not require incisions or anesthesia, provides a much easier and safer option compared to traditional surgical applications.",
            metaDescription: "ENDOLIFT is a combined laser application that provides facial contouring by reducing sagging and improving skin quality, offering these effects together.",
            blogDetailPageInfo: [
                {
                    title: "Endolift Application",
                    description: "ENDOlift is a combined laser application that offers skin renewal and facial contouring together. This application, which does not require incisions or anesthesia, provides a much easier and safer option compared to traditional surgical applications.",
                },
                {
                    title: "What is ENDOlift?",
                    description: "ENDOlift is an FDA-approved application that uses an optical fiber as thin as a hair strand to deliver laser energy under the skin, allowing comfortable treatment of sagging and wrinkles in the face, neck, and chin areas, under-eye bags, and all laxity and wrinkles in the body and legs. Since it scans the subcutaneous area like a network with a laser, this method is also called the 'Endolift Laser Network.' It is a technology that leaves behind anesthesia and difficult surgical procedures, can be performed in a clinical setting, and makes undergoing the knife for beauty a thing of the past.",
                },
                {
                    title: "What Should I Expect from ENDOlift?",
                    description: "ENDOlift melts localized excess fat in the middle and lower face and chin areas and tightens sagging skin. It makes the jawline more defined. It can reduce the appearance of horizontal lines in the neck area. It is effective in reducing all laxity and wrinkles in the body, especially in the abdomen and legs. It reduces wrinkles, bags, and loss of elasticity under the eyes. It increases skin elasticity and skin quality.",
                },
                {
                    title: "With Endolift;",
                    description: "<ul><li>Non-Surgical Facelift</li><li>Non-Surgical Neck Lift</li><li>Non-Surgical Chin Reduction</li><li>Jawline Definition</li><li>Non-Surgical Under-Eye Wrinkle and Bag Treatment</li><li>Body Laxity and Wrinkles</li></ul>",
                },
                {
                    title: "How Many Sessions of ENDOlift Are Applied?",
                    description: "A single session is sufficient. If desired, a second application can be done after 4-6 months.",
                },
                {
                    title: "Is ENDOlift a Painful Procedure?",
                    description: "It is a procedure with minimal pain. If the patient prefers, a topical anesthetic can be applied.",
                },
                {
                    title: "Is Any Care Required After the Application?",
                    description: "No special care is required. You can immediately return to your daily life afterward.",
                },
                {
                    title: "When Are the Results Seen?",
                    description: "Although the effect starts immediately after the application, the main effect begins to be seen after 1.5 months, continues to increase at the end of the 3rd month, and reaches its maximum level at the end of the 6th month. The effect lasts for an average of 2 years, depending on age and living conditions.",
                },
                {
                    title: "What Are the Advantages of ENDOlift?",
                    description: "<ul><li>It can be easily applied to adult men and women of all ages.</li><li>It does not require anesthesia or incisions.</li><li>It is painless.</li><li>It is a procedure that can be performed in a clinical setting.</li><li>A single session is sufficient.</li><li>It is a practical method that takes an average of one hour.</li><li>Aesthetic results are immediately visible.</li><li>The lifting effect lasts for a long time.</li><li>It improves skin quality.</li><li>No scars, bruising, or bleeding occur after the application.</li><li>Daily life can be resumed immediately after treatment.</li><li>It can be applied together with other procedures (fillers, Scarlet-X, mesotherapy).</li></ul>",
                },
            ]
        },
    },
    {
        applicationid: "device-procedures",
        applicationTitle: "Device Procedures",
        id: "scarlet-x",
        image: "/img/applications/cihaz-islemleri/scarlet-x.jpg",
        title: "Scarlet X",
        content: {
            blogPageTitle: "Scarlet X",
            blogPageDesc: "As we age, the skin is no longer as elastic and smooth as it used to be, which can lead to wrinkles and spots. However, there are many procedures that can help renew the skin and make it look younger. To learn more about Scarlet-X, which helps achieve a smooth and natural skin appearance, continue reading the blog post.",
            metaDescription: "SCARLET X is a new generation technology that provides a visible anti-aging effect on your skin by restructuring all layers of the skin simultaneously, reducing wrinkles and sagging, shrinking pores, and lightening spots.",
            blogDetailPageInfo: [
                {
                    title: "SCARLET X Application",
                    description: "As we age, the skin is no longer as elastic and smooth as it used to be, which can lead to wrinkles and spots. However, there are many procedures that can help renew the skin and make it look younger. To learn more about Scarlet-X, which helps achieve a smooth and natural skin appearance, continue reading the blog post.",
                },
                {
                    title: "For What Purpose Is Scarlet-X Applied?",
                    description: "This application, which can treat problems in all skin layers simultaneously, stimulates the subcutaneous tissue with radiofrequency waves using a specially designed head and initiates the rejuvenation process in the skin.",
                },
                {
                    title: "What Are the Uses of Scarlet-X?",
                },
                {
                    title: "1. Wrinkles",
                    description: "Scarlet X stimulates fibroblast cells that constitute the skin tissue architecture, increasing collagen and elastin production. Collagen is the component that ensures the firmness of our skin and protects it against gravity and sagging. Elastin provides elasticity in our skin, and the decrease in elastin fibers causes fine wrinkles in the skin. Because it is effective in improving sagging and loose skin appearance and reducing fine wrinkles, if total skin rejuvenation is considered, microneedling applications are a very appropriate choice.",
                },
                {
                    title: "2. Pores",
                    description: "Scarlet-X application regulates sebum (oil) secretion in the skin, shrinks pores, and also has the effect of reducing acne outbreaks.",
                },
                {
                    title: "3. Acne Scars",
                    description: "If acne affects the deeper layers of the skin, it can cause scars in the form of depressions in the skin. These scars become more pronounced over the years. The results are very satisfying when applied alone or together with other treatments to improve the appearance of acne scar marks.",
                },
                {
                    title: "4. Skin Stretch Marks (Striae)",
                    description: "It provides very satisfactory results in improving the appearance of skin stretch marks that develop due to weight gain and loss, pregnancy, rapid height growth, or corticosteroid use.",
                },
                {
                    title: "5. Scar and Burn Marks",
                    description: "It is used to improve the appearance of scars formed after surgery or trauma and ensures positive outcomes.",
                },
                {
                    title: "6. Skin Spots and Redness",
                    description: "It is effective in treating skin spots and fine capillaries, significantly reducing skin tone unevenness. In recent years, radiofrequency technology has been used for skin renewal and rejuvenation (anti-aging). We cannot stop the aging process, but we can slow it down. This slowing can be achieved by improving skin quality. With this technology, skin renewal is provided, reducing sagging and loose skin appearance and fine wrinkles, and delaying the aging process, offering a double effect. Radiofrequency is the gold standard in device procedures that improve skin quality. Scarlet-X is a highly advanced technology device manufactured in Silicon Valley, USA, with FDA approval in radiofrequency technology.",
                },
            ]
        },
        accordeon: [
            {
                title: "Is the Scarlet-X Procedure Painful?",
                description: "Personalized heads are used in the application. Pain threshold varies from person to person. Generally, minimal pain is felt. To minimize the possibility of pain, a topical anesthetic cream is applied to the treatment area about 30 minutes before the procedure. The procedure duration varies between half an hour and 45 minutes.",
            },
            {
                title: "When Should I Start Scarlet-X Application?",
                description: "The starting age is entirely determined by the individual's needs. If there are acne scars, it can be applied even under the age of 18. The age range for skin renewal is individual. We lose 1.5% of the collagen and elastin in our skin every year in our 20s. We do not notice this loss until the mid-30s. Applications that will prevent this loss and increase production will improve the quality of our skin. This effect can be achieved with mesotherapy in the 20s, but from the mid-30s onwards, device procedures should be started. If a person smokes, has insufficient or irregular sleep, eats poorly, or lives a stressful life, all these affect skin quality, and Scarlet-X application can be started at an earlier age (late 20s).",
            },
            {
                title: "What Procedures Are Done Along with Scarlet-X?",
                description: "The application is performed with a personalized head. With this specially designed head, microchannels extending from the top layers of your skin to the lower layers are formed. After the procedure, PRP or mesotherapy solutions prepared according to the needs of the person's skin are applied, as we can easily reach under the skin through the microchannels formed. PRP and mesotherapy applications enhance the results of the treatment.",
            },
            {
                title: "What Is the Recovery Time for Scarlet-X Treatment?",
                description: "The Scarlet-X application is an extremely safe system. There is no bruising, bleeding, or needle marks after the procedure. It can be applied in any season.",
            },
            {
                title: "Are There Any Side Effects After Scarlet-X Application?",
                description: "The Scarlet-X application is an extremely safe system. There is no bruising, bleeding, or needle marks after the procedure. It can be applied in any season.",
            },
            {
                title: "Scarlet-X Number of Sessions and Results",
                description: "In applications for skin rejuvenation, three sessions at monthly intervals will be sufficient. In the treatment of scars and acne scars, four to five treatment sessions at monthly intervals may be needed. It is important to repeat it every year to ensure the continuity of the skin renewal process.",
            },
            {
                title: "Why Is Scarlet-X Different?",
                description: "Scarlet-X is an FDA-approved device developed in Silicon Valley, USA.<br />Scarlet-X is an FDA-approved device clinically proven for safety and efficacy in rejuvenating the skin as a whole, reducing fine wrinkles, treating skin spots and redness, brightening skin tone, tightening pores, improving acne scars, and treating skin stretch marks (striae).",
            },
        ]
    },
    {
        applicationid: "device-procedures",
        applicationTitle: "Device Procedures",
        id: "laser-hair-bleaching",
        image: "/img/applications/cihaz-islemleri/lazerle-tuy-sarartma.jpg",
        title: "Laser Hair Bleaching",
        content: {
            blogPageTitle: "Laser Hair Bleaching",
            blogPageDesc: "What is Hair Bleaching and How Is It Applied? Hair bleaching is the process of lightening the color of fine hairs that the epilation device cannot see. The laser hair bleaching procedure, which is quite common, can be applied to almost every part of the body.",
            metaDescription: "Hair bleaching of fine hairs that the classic epilation laser cannot see with Q-switched ND-Yag Laser. Click for detailed information.",
            blogDetailPageInfo: [
                {
                    title: "What is Hair Bleaching and How Is It Applied?",
                    description: "Hair bleaching is the process of lightening the color of fine hairs that the epilation device cannot see. The laser hair bleaching procedure, which is quite common, can be applied to almost every part of the body. The most preferred body areas for this procedure are the mustache area, chin area, arms, cheeks, neck, legs, back, and abdomen. A Q-switched ND-Yag laser is used for this procedure. During the hair bleaching process, immediate lightening of the hairs is seen. The bleached hairs fall out two weeks after the application.",
                },
                {
                    description: "The regrowth time of the hairs that fall out after hair bleaching varies depending on the person's hair structure. The hairs start to regrow after an average of 2 to 3 months. When hairs start to regrow in the area, the hair bleaching procedure can be applied again. With regular repeated applications, the hairs become finer and less visible. At the same time, superficial spots in the hair area may also lighten.",
                },
                {
                    title: "How Is the Hair Bleaching Procedure Applied – Is It Painful?",
                    description: "Laser hair bleaching, often preferred by people who do not get results from laser epilation, is a widely chosen method today. Using wax, razors, or depilatories can cause annoying spots, especially in individuals prone to pigmentation, making laser hair bleaching the ideal solution for them.",
                },
                {
                    description: "The hair bleaching method is not a permanent solution. It can be repeated every 2-3 months on average. With repeated laser sessions, the hair follicles weaken over time, reducing the visibility of the hairs, and eventually making them almost invisible, thereby increasing the individual's daily comfort. The procedure takes about 15-20 minutes. The hair is lightened by laser shots on the hair. During the procedure, usually only a slight stinging sensation is felt. After the application, slight redness may occur in the treated area, but this redness usually disappears within a few hours.",
                },
                {
                    description: "The hair bleaching procedure is very safe as it can be applied to all skin types. Unlike epilation procedures, hair bleaching can also be performed in the summer. It is sufficient to avoid direct sunlight for a few days after the hair bleaching procedure. The fact that it can be applied in the summer makes hair bleaching very popular.",
                },
                {
                    description: "After laser hair bleaching, an increase in elastin and collagen secretion is observed in the skin. It is noted that the skin renews and skin tone unevenness decreases.",
                },
            ]
        },
    },
    {
        applicationid: "device-procedures",
        applicationTitle: "Device Procedures",
        id: "carbon-peeling",
        image: "/img/applications/cihaz-islemleri/karbon-peeling.jpg",
        title: "Carbon Peeling",
        content: {
            blogPageTitle: "Carbon Peeling",
            blogPageDesc: "Achieve a Brighter and Healthier Skin with Carbon Peeling. What is Carbon Peeling? Carbon peeling is effective in balancing excess oil in the skin, tightening enlarged pores, drying and preventing active acne, increasing skin tone and brightness, and eliminating superficial skin spots.",
            metaDescription: "Carbon peeling is effective in balancing oil in the skin, tightening pores, drying acne, increasing skin brightness, and treating superficial spots.",
            blogDetailPageInfo: [
                {
                    title: "Achieve a Brighter and Healthier Skin with Carbon Peeling",
                },
                {
                    title: "What is Carbon Peeling?",
                    description: "Carbon peeling is an effective skin rejuvenation treatment for balancing excess oil in the skin, tightening enlarged pores, drying and preventing active acne, increasing skin tone and brightness, and eliminating superficial skin spots. With carbon peeling, you can achieve a more radiant and lively skin free of dead skin cells. The carbon peeling procedure, applied with a Q-switched ND Yag laser, is a safe procedure with no side effects. Each laser shot causes the carbon molecules to explode, creating closure and tightening in the pores. The explosion of carbon molecules balances excess oil in the skin, thus drying active acne.",
                },
                {
                    title: "How Is Carbon Peeling Done?",
                    description: "In the carbon peeling procedure with a Q-switched ND Yag laser, a carbon particle solution is applied to the entire face. The solution is left on for 15-20 minutes to be absorbed into the skin. During this waiting period, the skin pores fully absorb the carbon solution. After the applied carbon dries, the procedure is performed by shooting with a Q-switched ND Yag Laser.",
                },
                {
                    description: "The intervals between carbon peeling sessions vary according to the patient's age, needs, and skin requirements. Depending on the patient's treatment condition, carbon peeling can be applied in 6-10 sessions at intervals of 1-2 weeks. Its effectiveness becomes noticeable after 3 sessions. The number of sessions varies depending on the severity of the skin problems.",
                },
                {
                    title: "What Are the Uses of Carbon Peeling?",
                    description: "* Treatment and prevention of active acne (pimples),",
                },
                {
                    description: "* Balancing excess oil in the skin,",
                },
                {
                    description: "* Pore tightening,",
                },
                {
                    description: "* Treatment of fine wrinkles (anti-aging),",
                },
                {
                    description: "* Increasing skin brightness,",
                },
                {
                    description: "* Treatment of facial spots (sun spots, melasma, superficial spots formed after procedures such as waxing),",
                },
                {
                    title: "What Are the Advantages?",
                    description: "It is a procedure that can be applied to all skin types and can be easily applied even to people with dark skin. It can be applied in any season. It is painless. The effect of the procedure becomes noticeable after the 3rd or 4th session. The procedure is short and practical. There is no crusting, peeling, or wounding of the skin after the procedure. The person can immediately continue with their normal life after the application.",
                },
            ]
        },
    },
    {
        applicationid: "device-procedures",
        applicationTitle: "Device Procedures",
        id: "laser-freckle-treatment",
        image: "/img/applications/cihaz-islemleri/lazerle-cil-tedavisi.jpg",
        title: "Laser Freckle Treatment",
        content: {
            blogPageTitle: "Laser Freckle Treatment",
            blogPageDesc: "Freckles (Ephelides) are mostly light brown spots with flat surfaces, 1-3 mm in size, seen on the face (especially on the cheeks, forehead, and nose), upper part of the body (back, décolleté, arms), and hands. They are generally seen in fair-skinned, light-eyed, red-haired individuals.",
            metaDescription: "Freckles (Ephelides) are mostly light brown spots with flat surfaces, 1-3 mm in size, seen on the face (especially on the cheeks, forehead, and nose), upper part of the body (back, décolleté, arms), and hands. You can get rid of your freckles with Q-switch ND Yag Laser treatment.",
            blogDetailPageInfo: [
                {
                    description: "Freckles (Ephelides) are mostly light brown spots with flat surfaces, 1-3 mm in size, seen on the face (especially on the cheeks, forehead, and nose), upper part of the body (back, décolleté, arms), and hands. They are generally seen in fair-skinned, light-eyed, red-haired individuals. While they can be structural, the sun is an important trigger. Therefore, they increase in summer and decrease in winter. Poor sunbathing and severe sunburns in the past are among the reasons.",
                },
                {
                    description: "To perform laser freckle treatment, the freckles must first be examined with dermoscopy. Although rare, it is of great importance to distinguish them from malignant lesions using dermoscopy. After these examinations, it is determined whether the patient is suitable for laser freckle treatment.",
                },
                {
                    title: "How Is Freckle Treatment Applied?",
                    description: "Q-switched ND Yag Laser, Fractional Laser, Erbium Yag Laser, and CO2 Laser are laser methods that can be used. Q-switched ND Yag Laser is the most preferred and most successful laser method in freckle treatment. The pain of the Q-switched ND Yag Laser procedure varies from person to person but has minimal pain. Therefore, the treatment area is numbed with an anesthetic cream before the procedure. After the procedure, there may be a tingling sensation for about half an hour. To reduce this sensation, cold compresses or cold mask applications are recommended.",
                },
                {
                    description: "After laser treatments, superficial crusts may form and last for 4-5 days. These crusts should heal gradually and fall off on their own. These are signs of the skin healing after the procedure. Therefore, these signs can also be considered positive signs. It should also be remembered that these signs are temporary.",
                },
                {
                    description: "After the procedure, the patient can immediately return to their daily life. The procedure does not negatively affect the patient's daily life. It is a laser type that provides reliable and successful results.",
                },
                {
                    title: "How Many Sessions Does the Treatment Take and When Do the Effects Start?",
                    description: "It is very important at what level the freckles extend within the layers of the skin. Therefore, superficial spots are usually treated in 2-3 sessions. However, spots that extend deeper are treated in 5-6 sessions. The intervals between sessions are 3-4 weeks. It is especially suitable to perform the treatment between autumn and spring.",
                },
                {
                    description: "In laser freckle treatment, about 30% success can be achieved after the first session. While complete results are obtained on some freckles, the color of others lightens but they are not completely treated. This depends on the color of the freckles and the skin structure. By the end of the sessions, an average success rate of 80-90% can be achieved in completely getting rid of freckles.",
                },
                {
                    title: "What to Pay Attention to After Laser Freckle Treatment:",
                    description: "The first 24 hours after treatment are very important. During this time, patients should not take a shower.",
                },
                {
                    description: "To reduce sensitivity and the tingling sensation after the application, you can apply cold compresses at regular intervals by consulting your doctor.",
                },
                {
                    description: "Sunscreen must be used.",
                },
                {
                    description: "Crusts should not be removed. Antibiotic or wound healing creams can be applied to the crusts.",
                },
            ]
        },
    },
    {
        applicationid: "device-procedures",
        applicationTitle: "Device Procedures",
        id: "laser-tattoo-removal",
        image: "/img/applications/cihaz-islemleri/lazerle-dovme-sildirme.jpg",
        title: "Laser Tattoo Removal",
        content: {
            blogPageTitle: "Laser Tattoo Removal",
            blogPageDesc: "Tattoos have attracted people from all cultures and all layers of society for centuries. From ancient Egyptian, Greek, and Roman cultures to the modern society we have today, tattoos have been used to enhance beauty, show uniqueness, express a sense of belonging, and sometimes to define oneself.",
            metaDescription: "Tattoo removal with Q-switched ND-YAG laser: Helios 3 laser treatment.",
            blogDetailPageInfo: [
                {
                    description: "Tattoos have attracted people from all cultures and all layers of society for centuries. From ancient Egyptian, Greek, and Roman cultures to the modern society we have today, tattoos have been used to enhance beauty, show uniqueness, express a sense of belonging, and sometimes to define oneself. There is no data indicating the frequency of tattoos in our country, but it is estimated that up to a quarter of young and middle-aged adults in the United States have at least one tattoo. As the number of people getting tattoos increases, so does the number of those who want them removed. People who seek tattoo removal usually do so because they think their tattoos are not suitable for their new job-career plan or workplace-new social environment, they have become tired of their tattoos, or they regret a tattoo made impulsively. Unfortunately, removing tattoos is more costly and time-consuming than getting them.",
                },
                {
                    description: "Many treatment methods for tattoo removal have emerged over time. Some of these include destructive techniques such as dermabrasion, cryosurgery, electrosurgery, and surgical excision. However, due to the high side effects of these techniques, laser technology has developed in recent years. Today, Q-switched laser has become the gold standard for tattoo removal.",
                },
                {
                    description: "After the tattoo removal procedure, only a small amount of tattoo colors (pigments) are shed from the skin, while the remaining pigments are digested by the body's immune system cells and removed from the area. This process takes weeks or even months. Therefore, laser tattoo removal is applied in sessions and intervals.",
                },
                {
                    title: "Who Cannot Have Tattoo Removal Laser?",
                    description: "Patients with keloid scars (raised scars).<br />Patients with a history of autoimmune disease.<br />Patients who have had acne treatment within the last 6 months.<br />Patients who have recently tanned or regularly use tanning beds.<br />Patients with active infection in the area to be treated.<br />Pregnancy and breastfeeding.",
                },
                {
                    title: "Is Tattoo Removal Painful?",
                    description: "During the procedure, you may feel your skin warming up or a sensation similar to pulling off a band-aid. Some people who have had tattoo removal report that the pain is comparable to getting the tattoo itself. To alleviate any discomfort, you can take a pain reliever at least one hour before the procedure.",
                },
                {
                    title: "What Determines the Response to Laser in Tattoo Removal?",
                    description: "<strong>Age of the tattoo;</strong> Newer tattoos have more dye, which prolongs the treatment duration. The tattoo must be at least one year old to be removed.",
                },
                {
                    description: "<strong>Depth and method of the tattoo;</strong> Superficial tattoos are easier to remove. However, as the tattoo penetrates deeper into the skin, it becomes more difficult for the laser light to reach. Therefore, amateur tattoos can be removed more easily and with fewer sessions than professional tattoos.",
                },
                {
                    description: "<strong>Skin type of the person;</strong> It is more difficult to remove tattoos in people with dark skin.",
                },
                {
                    description: "<strong>Location of the tattoo;</strong> The closer the application area is to the lymph nodes, the more effective the treatment. The results are more satisfactory in areas close to the neck and waist, such as the upper arm, upper leg, and torso, while the response is slower on the hand, wrist, foot, and lower leg areas.",
                },
                {
                    description: "<strong>The color and type of the tattoo are extremely important.</strong> Black, dark blue, red, orange, and purple respond well to laser, but green and yellow are difficult and require longer sessions. There is no laser to remove turquoise tattoos, making their removal impossible. Red tattoos may darken or turn black after laser treatment, which is an expected situation and the tattoo will eventually be removed. Red tattoos may cause swelling of the lymph nodes as an allergic reaction after laser treatment.",
                },
                {
                    title: "What to Pay Attention to After Tattoo Removal?",
                    description: "After the tattoo removal procedure, you will see small white circles in the tattooed area. These may turn into redness and swelling that will resolve within a day. Twenty-four hours after the procedure, a thick crust will start to form on the treated tattoo area. Do not worry, as this is the natural response of the skin to healing. The crust will fall off within 2 weeks. During this period, you will gradually notice the fading of the color and shape of the tattoo. Sessions should be at intervals of 6-8 weeks to allow your skin to heal.",
                },
                {
                    description: "We recommend using an antibiotic cream 2-3 times a day for 10-14 days. Blisters filled with water may form on the treated area. These blisters may burst on their own, but if they are large, you should consult your doctor to have them drained sterilely. You need to protect the treated area from the sun and use sunscreen. If your tattoo is in a visible area and you live in a place with high sun exposure, it is more appropriate to have the tattoo removal procedure done in winter.",
                },
                {
                    title: "How Many Sessions Are Needed for Tattoo Removal?",
                    description: "While a definitive number cannot be given, the average is 6-8 sessions. This number is 3-5 sessions for amateur tattoos and 5-12 sessions for professional tattoos. Sessions should be at intervals of 6-8 weeks to allow your skin to heal.",
                }
            ]
        }
    },
    {
        applicationid: "medical-applications",
        applicationTitle: "Medical Dermatology",
        id: "alopecia-areata",
        image: "/img/applications/medikal-uygulamalar/alopesi-areata.jpg",
        title: "What Is Alopecia Areata?",
        content: {
            blogPageTitle: "What Is Alopecia Areata?",
            blogPageDesc: "Alopecia is a general term used for hair loss. Alopecia areata is a common cause of hair loss that can occur at any age. The disease can affect any area with hair, such as the beard, eyebrows, eyelashes, body, arms, and legs, but it usually causes small, coin-sized, round bald patches on the scalp. Sometimes it can affect the entire scalp or result in total hair loss on the scalp and body. It is not possible to predict the extent of hair loss in advance. The severity of the disease varies from person to person. In typical alopecia areata, hair regrowth can take months or even years, but this is not guaranteed. The new hair that grows back is sometimes white and fine; over time, the color darkens, and the thickness increases. If all the hair on the scalp is lost, the chance of regrowth is low.",
            metaDescription: "Alopecia areata (alopecia) usually causes small, coin-sized, round bald patches on the scalp. It can also affect areas like the beard, eyebrows, and eyelashes.",
            blogDetailPageInfo: [
                {
                    description: "Alopecia is a general term used for hair loss. Alopecia areata is a common cause of hair loss that can occur at any age. The disease can affect any area with hair, such as the beard, eyebrows, eyelashes, body, arms, and legs, but it usually causes small, coin-sized, round bald patches on the scalp. Sometimes it can affect the entire scalp or result in total hair loss on the scalp and body. It is not possible to predict the extent of hair loss in advance. The severity of the disease varies from person to person. In typical alopecia areata, hair regrowth can take months or even years, but this is not guaranteed. The new hair that grows back is sometimes white and fine; over time, the color darkens, and the thickness increases. If all the hair on the scalp is lost, the chance of regrowth is low.",
                },
                {
                    title: "What Are the Causes of Alopecia Areata?",
                    description: "Hair falls out in the disease because the hair follicles of the affected individuals are not recognized as their own by the immune system and are rejected as foreign (autoimmunity). It is not known why this happens, why only certain areas are affected, and why the hair grows back.",
                },
                {
                    title: "Is There a Cause for Alopecia Areata?",
                    description: "The disease is not contagious, it is not related to vitamin deficiency or what you eat. Intense stress, especially in children, such as having a new sibling, starting school, mourning, and accidents, can sometimes be a trigger for the disease.",
                },
                {
                    title: "Is Alopecia Areata Hereditary?",
                    description: "There is a genetic predisposition to the disease, and close family members can be affected.",
                },
                {
                    title: "What Are the Symptoms of Alopecia Areata?",
                    description: "The disease usually does not cause any complaints.",
                },
                {
                    title: "How Does Alopecia Areata Appear?",
                    description: "The disease typically begins with one or more bald patches on the scalp, which are smooth and well-defined, with no redness or scaling. Sometimes, well-defined areas of hair loss can occur, and more rarely, widespread hair loss. Regrowth usually starts with fine white hair in the center of the bald area, which thickens and darkens over time, regaining its color.",
                },
                {
                    title: "Can Alopecia Areata Be Treated?",
                    description: "If hair loss is limited to a few well-defined areas, it tends to be milder, and hair can completely regrow within a year without treatment (about 60-80%). However, hair loss attacks may recur in the future.",
                },
                {
                    title: "How Is Alopecia Areata Treated?",
                    description: "If there are one or two areas of hair loss, patients may be left untreated as treatments are intended to accelerate hair regrowth. If there is excessive hair loss initially, the improvement may not be good.",
                },
                {
                    description: "You need to consult a dermatologist to plan the treatment.",
                },
            ]
        },
    },
    {
        applicationid: "medical-applications",
        applicationTitle: "Medical Dermatology",
        id: "keloid",
        image: "/img/applications/medikal-uygulamalar/keloid.jpg",
        title: "What Is Keloid?",
        content: {
            blogPageTitle: "What Is Keloid?",
            blogPageDesc: "Keloid is a condition characterized by excessive scar formation resulting from an abnormal response in wound healing, appearing on the skin, especially on the upper parts of the body, and affecting individuals functionally and cosmetically.",
            metaDescription: "A raised, pink-purple itchy bump at the site of wound healing.",
            blogDetailPageInfo: [
                {
                    title: "Why Does Keloid Occur?",
                    description: "After any trauma that disrupts the integrity of the skin, a scar (scar tissue) can appear at the end of the repair process. A keloid is a raised, firm, and smooth scar that becomes larger than the original wound scar as a result of the body's excessive stimulation of wound healing during the skin's healing process. This condition, which results in excessive scarring beyond expectations, is called 'Keloid.' They can be different colors ranging from skin tone to red and even purple. Although keloid scars do not affect physical health, they can affect a person's quality of life psychologically. As the keloid scar thickens, it becomes itchy. This itching can be severe enough to socially bother the person. Treatments can help reduce this itching.",
                },
                {
                    title: "Different skin injuries that can cause keloids:",
                    description: "Deep cuts<br />Scars after severe acne<br />Surgical scars<br />Piercings<br />Tattoos<br />Rarely; insect bites and injection sites.",
                },
                {
                    title: "What Are the Risk Factors for Developing Keloid?",
                    description: "People with a family history of keloid are more likely to develop it. Keloids are more common in certain areas of the body, such as the shoulders, back, upper torso, neck, and earlobes. All factors that delay and disrupt wound healing can lead to significant scar tissue formation.",
                },
                {
                    description: "Infection in the wound, repeated trauma, keeping the wound dry, or too moist can delay healing.",
                },
                {
                    title: "What Are the Treatment Options?",
                    description: "Treatment methods include applying treatments that prevent keloid formation after any cut if there is a personal or family history of keloid. If a keloid has already developed, creams or injections to thin the keloid are applied. Treatments can also reduce or eliminate the itching in the keloid scar. The keloid scar can be thinned to the level of the skin, but the pink-purple discoloration may persist.",
                },
            ]
        },
    },
    {
        applicationid: "medical-applications",
        applicationTitle: "Medical Dermatology",
        id: "what-you-need-to-know-about-acne",
        image: "/img/applications/medikal-uygulamalar/akne-sivilce.jpg",
        title: "What You Need to Know About Acne",
        content: {
            blogPageTitle: "What You Need to Know About Acne",
            blogPageDesc: "Acne vulgaris is a chronic inflammatory disease of the hair follicles and sebaceous glands. It usually begins during adolescence, but the end time varies.",
            metaDescription: "Acne (pimples) are red, inflamed, raised lesions that settle on the face, back, chest, and shoulders, where sebaceous glands are abundant.",
            blogDetailPageInfo: [
                {
                    description: "Acne vulgaris is a chronic inflammatory disease of the hair follicles and sebaceous glands. It usually begins during adolescence, but the end time varies.",
                },
                {
                    title: "There are four main components in the causes of acne formation:",
                    description: "a) Increase in sebum (oil)<br />b) Blockage of hair follicles<br />c) Proliferation of bacteria in hair follicles (Propionibacterium Acnes)<br />d) Inflammatory process around the hair follicle",
                },
                {
                    description: "Acne (pimples) are raised, red, inflamed lesions that settle on the face, back, chest, and shoulders, where sebaceous glands are abundant. The initial lesions are blackheads. Raised redness on the skin is the first sign of inflammation, followed by pus-filled blisters. Rarely, cysts can form, causing large oil glands and nodules under the skin. The most important factor in acne treatment is a good doctor-patient communication. Depending on the severity of the acne, topical treatments (creams), oral medications, or medical cosmetic procedures can be applied. Regardless of the method, the treatment requires patience, lasting at least three to six months.",
                },
                {
                    title: "Is There a Relationship Between Food and Acne?",
                    description: "No direct connection has been found.<br />• The role of diet in acne is controversial.<br />• However, foods with a high glycemic index (RAISE BLOOD SUGAR LEVELS QUICKLY) should be avoided.<br />• Milk and dairy products should be consumed in moderation.<br />• Saturated fats may exacerbate acne.<br />• Avoid foods that you notice increase your acne!",
                },
                {
                    title: "Examples of Foods Likely to Cause Acne",
                    description: "<strong>Foods with a high glycemic load*</strong><br />Foods containing sugar and glucose syrup<br />Flour-based foods (bread, pasta, etc.)<br />Potatoes<br />Carrots<br />Rice<br />Cornflakes<br />Fruits with high sugar content (watermelon, grapes, bananas)<br />Processed foods<br />Energy drinks<br />Protein powders<br />Milk and dairy products<br />Milk (regardless of fat content)<br />Cheese, yogurt, kefir made with industrial yeast<br />Ice cream<br />Whey<br />There are also reports that acne is less common in people who eat vegetables, fruits, and fish. There is insufficient data on antioxidants, fish oil, zinc, and probiotics.",
                },
                {
                    title: "Can Insulin Resistance and Obesity Trigger Acne?",
                    description: "Insulin resistance can cause acne by increasing the production of oil (sebum) on the face. Acne may increase as weight is gained. At the same time, insulin resistance increases the production of androgens (male hormones), increasing acne formation.",
                },
                {
                    title: "Do Hormones Play a Role in Acne Development?",
                    description: "Androgens (male hormones) play a role in acne development.<br />• The reason it develops after puberty is due to these hormones.<br />Insulin resistance increases the production of androgens (male hormones).<br />• Polycystic ovary syndrome: in those with ovarian cysts; Hirsutism + weight gain + irregular menstruation",
                },
                {
                    description: "Not every person with ovarian cysts has polycystic ovary syndrome. The number and size of the cysts, along with different accompanying symptoms, are considered for diagnosis. A menstrual cycle of 28-40 days is considered normal. If there is irregular menstruation, it must continue for at least 6 months to be considered.",
                },
                {
                    description: "Not Every Person with Ovarian Cysts = Polycystic Ovary Syndrome.",
                },
                {
                    title: "Is There a Relationship Between Acne and Smoking?",
                    description: "• The frequency of acne may increase in smokers.<br />• There is thought to be a relationship between the amount of smoking and the severity of acne.",
                },
                {
                    title: "Is There a Relationship Between Stress, Irregular Sleep, and Acne?",
                    description: "• Stress can increase acne.<br />• Adrenal hormones, which increase with stress, trigger acne.<br />• Inadequate and poor-quality sleep also increases stress hormones, leading to the formation of acne or an increase in the severity of acne.",
                },
                {
                    title: "How Should Acne Treatment Be Planned?",
                    description: "• Treatment varies from person to person.<br />• The severity of acne is important.<br />• There is no single treatment & several treatments can be applied together.<br />• THE MOST IMPORTANT POINT IS PATIENCE!!!!<br />• It takes at least 2-3 months of treatment to see an effect.<br />• Your acne may recur.<br />• CONTINUATION OF TREATMENT IS VERY IMPORTANT!<br />• After treatment is completed, the recommended cream should be continued 1-2 days a week.",
                },
                {
                    title: "Hormonal Treatment in Acne",
                    description: "• It is recommended only for female patients.<br />• Hormonal treatment, especially for women with signs of androgen (male hormones) excess (excessive hair growth, thinning hair) and acne.<br />• Birth control pills are most commonly used.<br />• Effects are not observed before 3-6 months of treatment.",
                },
                {
                    title: "Scar and Blemish Treatment",
                    description: "• In the early stages, blemishes can disappear completely and quickly with some blemish creams and sun protection.<br />• Scar treatment is difficult and requires patience.<br />• Therefore, it is very important to provide treatment as early as possible in people with acne to prevent scar formation from the start.<br />",
                },
            ]
        },
    },

];

export default enApplication;